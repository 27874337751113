<template>
  <card-item image class="catalogue-item">
    <template #card-image>
      <figure class="image is-4by3" @click="buttonClick">
        <img :src="item.imageUrl" />
      </figure>
    </template>
    <template #card-content>
      <h6 class="title is-6" @click="buttonClick">{{ item.name }}</h6>
      <p>{{ `${$t(`product-details.${item.name.toLowerCase().split(' ').join('_')}_description`).substring(0,109)}...` }}</p>
      <button @click="buttonClick">
          {{ $t('product-details.details') }}
      </button>
    </template>
  </card-item>
</template>

<script>
    import CardItem from "@diabfoot/component-card/CardItem";
    import './catalogue-item.scss';
    export default {
        components: {CardItem},
        props: {
            item: Object
        },
        inject: ["route"],
        methods: {
            buttonClick() {
              window.location.hash = `#flow/${this.item.id}/${this.route.groups[1]}`
            },
            $t(key) {
                return this.$i18n.t(key);
            }
        }
    }
</script>

<style>
    .catalogue-item {
        min-height: 200px;
    }
    .catalogue-item-stack-element {
        position: absolute;
        bottom: 0;
        left: 0;
        background: rgba(40, 40, 40, 0.5);
        width: 100%;
        padding: 4px;
    }
    .catalogue-item-stack {
        position: relative;
    }
</style>