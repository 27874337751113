import chalk from "chalk";

let loggerName = "NOT SET";
if (typeof window !== "undefined" && typeof window.LOGGER_NAME === "string") {
    loggerName = window.LOGGER_NAME;
}
if (typeof process !== "undefined" && typeof process.env !== "undefined") {
    if (typeof process.env.SERVICE_IDENTIFIER === "string") {
        loggerName = process.env.SERVICE_IDENTIFIER;
    } else {
        loggerName = process.env.INIT_CWD.split("/").pop();
    }
}

export function commitLoggerName(name) {
    loggerName = name;
}

export function logEntry(tag, ...args) {
    const now = new Date();
    const timezoneOffsetMinutes = -1 * now.getTimezoneOffset();
    const timezoneOffsetWholeHours = Math.floor(timezoneOffsetMinutes / 60);
    const timezoneOffsetRestMinutes = timezoneOffsetMinutes % 60;
    const displayedTimeZoneOffset = `${timezoneOffsetMinutes < 0 ? '-' : '+'}${timezoneOffsetWholeHours}:${timezoneOffsetRestMinutes < 10 ? "0" : ""}${timezoneOffsetRestMinutes}`;
    const displayedHours = now.getHours() < 10 ? `0${now.getHours()}` : now.getHours();
    const displayedMinutes = now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();
    const displayedSeconds = now.getSeconds() < 10 ? `0${now.getSeconds()}` : now.getSeconds();
    const displayedMilliseconds = now.getMilliseconds() < 10 ? `00${now.getMilliseconds()}` : now.getMilliseconds() < 100 ? `0${now.getMilliseconds()}` : now.getMilliseconds();
    const displayedTime = `${displayedHours}:${displayedMinutes}:${displayedSeconds}.${displayedMilliseconds}`;
    const loggedDate = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()} ${displayedTime} ${displayedTimeZoneOffset}`;
    console.log(`[${chalk.blue(loggedDate.padEnd(30))}]`, `<${chalk.bold(loggerName.padEnd(30))}>`, `(${tag.padStart(20)})`, ...args);
}

export function logInfo(...args) {
    logEntry(chalk.green("ℹ️ Info"), ...args);
}

export function logWarn(...args) {
    logEntry(chalk.yellow("⚠️ Warn"), ...args);
}

export function logError(...args) {
    logEntry(chalk.red("⛔️ Error"), ...args);
}

export function logDebug(...args) {
    logEntry(chalk.magenta("🐛 Debug"), ...args);
}
