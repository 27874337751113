<template>
    <div>
        <p v-if="error">Hiba történt: {{ error }}</p>
        <loading v-if="loading"></loading>
        <div v-else class="size">
            <div class="header-line">
                <h3>{{ $t('sizing') }}</h3>
                <div class="orange-line"></div>
                <button @click="resetStep">{{ $i18n.t('flow.restart') }}</button>
            </div>
            <p>
                {{ $t('main_text') }}
            </p>
            <div class="columns is-justify-content-center">
                <div class="column is-four-fifths image-upload-box">
                    <img src="./assets/image_icon.svg" />
                    <p>{{ $t('image_upload_text') }}</p>
                    <button @click="openModal">{{ $t('browse') }}</button>
                </div>
            </div>
            <file-upload ref="images"></file-upload>
            <div class="columns">
                <div class="column">
                    <h2>{{ $t('foot_length_left') }}</h2>
                    <div class="slider">
                        <div class="unit-wrapper">
                            <input type="number" v-model="sizes.leftLength" />
                            <span>{{ leftLength.size.unit }}</span>
                        </div>
                        <slider
                            v-model="sizes.leftLength"
                            track-color="#D3D3D3"
                            color="#738FC6"
                            :max="leftLength.size.max"
                            :handleScale="2"
                        />
                    </div>
                    <p>
                        {{ $t('foot_length_text') }}
                    </p>
                </div>
                <div class="column is-flex is-justify-content-center">
                    <figure class="image">
                        <img src="./assets/length.png" style="width: 188px;" />
                    </figure>
                </div>
                <div class="column">
                    <h2>{{ $t('foot_length_right') }}</h2>
                    <div class="slider">
                        <div class="unit-wrapper">
                            <input type="number" v-model="sizes.rightLength" />
                            <span>{{ rightLength.size.unit }}</span>
                        </div>
                        <slider
                            v-model="sizes.rightLength"
                            track-color="#D3D3D3"
                            color="#738FC6"
                            :max="rightLength.size.max"
                        />
                    </div>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <h2>{{ $t('bunion_width_left') }}</h2>
                    <div class="slider">
                        <div class="unit-wrapper">
                            <input
                                type="number"
                                v-model="sizes.leftBunnionWidth"
                            />
                            <span>{{ leftBunnionWidth.size.unit }}</span>
                        </div>
                        <slider
                            v-model="sizes.leftBunnionWidth"
                            track-color="#D3D3D3"
                            color="#738FC6"
                            :max="leftBunnionWidth.size.max"
                        />
                    </div>
                    <p>
                        {{ $t('bunion_width_text') }}
                    </p>
                </div>
                <div class="column is-flex is-justify-content-center">
                    <figure class="image">
                        <img src="./assets/butyok.png" style="width: 240px;" />
                    </figure>
                </div>
                <div class="column">
                    <h2>{{ $t('bunion_width_right') }}</h2>
                    <div class="slider">
                        <div class="unit-wrapper">
                            <input
                                type="number"
                                v-model="sizes.rightBunnionWidth"
                            />
                            <span>{{ rightBunnionWidth.size.unit }}</span>
                        </div>
                        <slider
                            v-model="sizes.rightBunnionWidth"
                            track-color="#D3D3D3"
                            color="#738FC6"
                            :max="rightBunnionWidth.size.max"
                        />
                    </div>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <h2>{{ $t('bridge_width_left') }}</h2>
                    <div class="slider">
                        <div class="unit-wrapper">
                            <input type="number" v-model="sizes.leftBridgeSize" />
                            <span>{{ leftBridgeSize.size.unit }}</span>
                        </div>
                        <slider
                            v-model="sizes.leftBridgeSize"
                            track-color="#D3D3D3"
                            color="#738FC6"
                            :max="leftBridgeSize.size.max"
                        />
                    </div>
                    <p>
                        {{ $t('bridge_width_text') }}
                    </p>
                </div>
                <div class="column is-flex is-justify-content-center">
                    <figure class="image">
                        <img src="./assets/bridge.png" style="width: 240px;" />
                    </figure>
                </div>
                <div class="column">
                    <h2>{{ $t('bridge_width_right') }}</h2>
                    <div class="slider">
                        <div class="unit-wrapper">
                            <input
                                type="number"
                                v-model="sizes.rightBridgeSize"
                            />
                            <span>{{ rightBridgeSize.size.unit }}</span>
                        </div>
                        <slider
                            v-model="sizes.rightBridgeSize"
                            track-color="#D3D3D3"
                            color="#738FC6"
                            :max="rightBridgeSize.size.max"
                        />
                    </div>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <h2>{{ $t('heel_width_left') }}</h2>
                    <div class="slider">
                        <div class="unit-wrapper">
                            <input
                                type="number"
                                v-model="sizes.leftHeelCircumference"
                            />
                            <span>{{ leftHeelCircumference.size.unit }}</span>
                        </div>
                        <slider
                            v-model="sizes.leftHeelCircumference"
                            track-color="#D3D3D3"
                            color="#738FC6"
                            :min="leftHeelCircumference.size.min"
                            :max="leftHeelCircumference.size.max"
                        />
                    </div>
                    <p>
                        {{ $t('heel_width_text') }}
                    </p>
                </div>
                <div class="column is-flex is-justify-content-center">
                    <figure class="image">
                        <img src="./assets/heel.png" style="width: 154px;" />
                    </figure>
                </div>
                <div class="column">
                    <h2>{{ $t('heel_width_right') }}</h2>
                    <div class="slider">
                        <div class="unit-wrapper">
                            <input
                                type="number"
                                v-model="sizes.rightHeelCircumference"
                            />
                            <span>{{ rightHeelCircumference.size.unit }}</span>
                        </div>
                        <slider
                            v-model="sizes.rightHeelCircumference"
                            track-color="#D3D3D3"
                            color="#738FC6"
                            :min="rightHeelCircumference.size.min"
                            :max="rightHeelCircumference.size.max"
                        />
                    </div>
                </div>
            </div>
            <div class="columns is-justify-content-center">
                <div class="column is-narrow is-flex is-justify-content-center">
                    <button class="back-button" @click="onBack">
                        {{ $i18n.t('flow.back') }}
                    </button>
                    <button class="next-button" @click="onFinish">
                        {{ $i18n.t('flow.next') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import slider from "vue3-slider";
import "./size.scss";

import { customizerClient } from "@diabfoot/client-cache";
import Loading from "@diabfoot/component-loading/Loading";
import FileUpload from "@diabfoot/component-file-upload";

export default {
    name: "Size",
    components: {
        Slider: slider,
        Loading,
        FileUpload
    },
    props: {
        productId: String,
    },
    data() {
        return {
            sizes: {
                leftLength: 100,
                rightLength: 100,
                leftBunnionWidth: 100,
                rightBunnionWidth: 100,
                leftBridgeSize: 100,
                rightBridgeSize: 100,
                leftHeelCircumference: 100,
                rightHeelCircumference: 100,
            },

            leftLength: null,
            rightLength: null,
            leftBunnionWidth: null,
            rightBunnionWidth: null,
            leftBridgeSize: null,
            rightBridgeSize: null,
            leftHeelCircumference: null,
            rightHeelCircumference: null,

            customizations: [],
            loading: true,
            error: null,
        };
    },
    async mounted() {
        const customizations = await customizerClient.listCustomizations({
            productId: this.productId,
        });

        this.customizations = customizations.filter(
            (customization) => customization.type === "SIZE"
        );

        this.leftLength = this.customizations.find((c) =>
            c.id.endsWith("-leftLength")
        );
        this.rightLength = this.customizations.find((c) =>
            c.id.endsWith("-rightLength")
        );
        this.leftBunnionWidth = this.customizations.find((c) =>
            c.id.endsWith("-leftBunnionWidth")
        );
        this.rightBunnionWidth = this.customizations.find((c) =>
            c.id.endsWith("-rightBunnionWidth")
        );
        this.leftBridgeSize = this.customizations.find((c) =>
            c.id.endsWith("-leftBridgeSize")
        );
        this.rightBridgeSize = this.customizations.find((c) =>
            c.id.endsWith("-rightBridgeSize")
        );
        this.leftHeelCircumference = this.customizations.find((c) =>
            c.id.endsWith("-leftHeelCircumference")
        );
        this.rightHeelCircumference = this.customizations.find((c) =>
            c.id.endsWith("-rightHeelCircumference")
        );

        this.loading = false;
    },
    methods: {
        async onBack() {
            this.$emit("back");
        },
        async onFinish() {
            this.$emit("finish-customization", [
                {
                    customizationId: this.leftLength.id,
                    size: this.sizes.leftLength,
                },
                {
                    customizationId: this.rightLength.id,
                    size: this.sizes.rightLength,
                },
                {
                    customizationId: this.leftBunnionWidth.id,
                    size: this.sizes.leftBunnionWidth,
                },
                {
                    customizationId: this.rightBunnionWidth.id,
                    size: this.sizes.rightBunnionWidth,
                },
                {
                    customizationId: this.leftBridgeSize.id,
                    size: this.sizes.leftBridgeSize,
                },
                {
                    customizationId: this.rightBridgeSize.id,
                    size: this.sizes.rightBridgeSize,
                },
                {
                    customizationId: this.leftHeelCircumference.id,
                    size: this.sizes.leftHeelCircumference,
                },
                {
                    customizationId: this.rightHeelCircumference.id,
                    size: this.sizes.rightHeelCircumference,
                },
            ]);
        },
        resetStep() {
            this.$emit('reset');
        },
        $t(key) {
            return this.$i18n.t(`flow.sizes.${key}`);
        },
        openModal() {
            this.$refs.images.openModal();
        }
    },
};
</script>

<style scoped></style>
