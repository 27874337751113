<template>
    <scaffold fluid class="landing-page">
        <template #content>
            <div class="finalize-modal" id="finalize-modal">
                <div class="modal-bg"></div>
            </div>
          <div class="content">
            <div class="main-text">
              <h1>{{ $t('custom_shoes_header') }}</h1>
              <p>{{ $t('custom_shoes_text') }}</p>
<!--              <button>
                  {{ $t('custom_shoes_button') }}
              </button>-->
            </div>
            <div class="main-image">
            </div>
          </div>
          <div class="cards columns custom-shoes-catalogs">
            <div class="column">
              <card-item image>
                <template #card-image>
                  <figure class="image is-16by9">
                    <img src="./assets/custom-lady-image.jpg" />
                  </figure>
                </template>
                <template #card-content>
                  <h6 class="title is-6">{{ $t('custom_womens_shoes') }}</h6>
<!--                  <p>Azoknak, akik vigyázni szeretnének a lábukra, kezdeti stádiumban levő diabéteszük van, vagy átlagos komfort cipő gyári betétjét szeretnék helyettesíteni vele. Antibakteriális, nedvtaszító alcantara jellegű fedőrétegével kényelmes és puha talpbetét.</p>-->
                  <button @click="toCatalog(2)">{{ $t('details') }}</button>
                </template>
              </card-item>
            </div>
            <div class="column">
                <card-item image>
                    <template #card-image>
                        <figure class="image is-16by9">
                            <img src="./assets/custom-children-image.png" />
                        </figure>
                    </template>
                    <template #card-content>
                        <h6 class="title is-6">{{ $t('custom_kids_shoes') }}</h6>
                        <!--                  <p>A speciális talpbetét fekély kezelésére, a metatarsatus fejecsek tehermentesítésére alkalmas. Olyan diabéteszeseknek javasolt, akiknek már seb alakult ki a lábán.</p>-->
                        <button @click="toCatalog(4)">{{ $t('details') }}</button>
                    </template>
                </card-item>
            </div>
            <div class="column">
              <card-item image>
                <template #card-image>
                  <figure class="image is-16by9">
                    <img src="./assets/custom-men-image.jpg" />
                  </figure>
                </template>
                <template #card-content>
                  <h6 class="title is-6">{{ $t('custom_mens_shoes') }}</h6>
<!--                  <p>Csiszolással adaptálható hossz és harántboltozati emeléssel készült puha betét diabéteszes lábra. Elegáns, puha, antibakteriális tulajdonságokkal rendelkező betét.</p>-->
                  <button @click="toCatalog(1)">{{ $t('details') }}</button>
                </template>
              </card-item>
            </div>
            <div class="column">
              <card-item image>
                <template #card-image>
                  <figure class="image is-16by9">
                      <img src="./assets/custom-trend-image.jpg" />
                  </figure>
                </template>
                <template #card-content>
                  <h6 class="title is-6">{{ $t('custom_trend_shoes') }}</h6>
                  <!--                  <p>A speciális talpbetét fekély kezelésére, a metatarsatus fejecsek tehermentesítésére alkalmas. Olyan diabéteszeseknek javasolt, akiknek már seb alakult ki a lábán.</p>-->
                  <button @click="toCatalog(3)">{{ $t('details') }}</button>
                </template>
              </card-item>
            </div>
          </div>
          <div class="custom-shoes-process-container">
            <div class="header-line">
              <h3>{{ $t('how_will_you_have_a_custom_shoe') }}</h3>
              <div class="orange-line"></div>
            </div>
            <div class="columns">
              <div class="column">
                <card-item>
                  <template #card-content>
                    <h1 class="title is-1">1.</h1>
                    <p>{{ $t('flow.1') }}</p>
                  </template>
                </card-item>
              </div>
              <div class="column">
                <card-item>
                  <template #card-content>
                    <h1 class="title is-1">2.</h1>
                    <p>{{ $t('flow.2') }}</p>
                  </template>
                </card-item>
              </div>
              <div class="column">
                <card-item>
                  <template #card-content>
                    <h1 class="title is-1">3.</h1>
                    <p>{{ $t('flow.3') }}</p>
                  </template>
                </card-item>
              </div>
              <div class="column">
                <card-item>
                  <template #card-content>
                    <h1 class="title is-1">4.</h1>
                    <p>{{ $t('flow.4') }}</p>
                  </template>
                </card-item>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <card-item>
                  <template #card-content>
                    <h1 class="title is-1">5.</h1>
                    <p>{{ $t('flow.5') }}</p>
                  </template>
                </card-item>
              </div>
              <div class="column">
                <card-item>
                  <template #card-content>
                    <h1 class="title is-1">6.</h1>
                    <p>{{ $t('flow.6') }}</p>
                  </template>
                </card-item>
              </div>
              <div class="column">
                <card-item>
                  <template #card-content>
                    <h1 class="title is-1">7.</h1>
                    <p>{{ $t('flow.7') }}</p>
                  </template>
                </card-item>
              </div>
              <div class="column"></div>
            </div>
          </div>
<!--          <div class="shoes-parts">
            <div class="header-line">
              <h3>Miként lesz egyedi cipője?</h3>
              <div class="orange-line"></div>
            </div>
            <div class="content">

            </div>
          </div>-->
        </template>
    </scaffold>
</template>

<script>
    import Scaffold from "@diabfoot/component-scaffold"
    import CardItem from "@diabfoot/component-card/CardItem";
    import "./landing.scss";
    export default {
        components: { Scaffold, CardItem },
        inject: ['route'],
        async mounted() {
            if (this.route.groups) {
                const modal = document.getElementById('finalize-modal');
                modal.style.display = 'initial';
                await new Promise(r => setTimeout(r, 2000));
                modal.style.display = 'none';
            }
        },
        methods: {
            toCatalog(catalogId) {
                window.location.hash = `#catalogue/${catalogId}`
            },
            $t(key) {
                return this.$i18n.t(`landing-page.${key}`);
            }
        }
    }
</script>

<style>
</style>