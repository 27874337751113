<template>
    <div class="scaffold">
        <!-- START NAV -->
        <nav class="navbar">
            <div class="container is-fluid">
              <div class="navbar-brand">
                <a class="navbar-item" href="#landing-page">
                  <img src="./assets/logo_config.svg" width="151" height="62" />
                  <div class="vertical-line is-hidden-touch"></div>
                  <div class="navbar-brand-text">
                    {{ $t('configurator_header') }}
                  </div>
                </a>
                <div class="navbar-end is-hidden-touch">
                  <div class="navbar-item">
                    <button @click="droorto()">
                      {{ $t('drorto_website') }}
                    </button>
                  </div>
                  <div class="navbar-item">
                    <div class="dropdown" id="language-dropdown">
                      <div class="dropdown-trigger">
                        <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="onDropdown">
                          <span>{{ $t('language') }}</span>
                        </button>
                      </div>
                      <div class="dropdown-menu" id="dropdown-menu" role="menu">
                        <div class="dropdown-content">
                          <a href="#" @click="selectLanguage('en')" class="dropdown-item">
                            {{ $t('english') }}
                          </a>
                          <a class="dropdown-item" @click="selectLanguage('hu')">
                            {{ $t('hungarian') }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="navbar-end is-hidden-desktop">
                    <div class="navbar-item select is-rounded">
                        <select @change="selectLanguage" v-model="language">
                            <option value="en">{{ $t('english') }}</option>
                            <option value="hu">{{ $t('hungarian') }}</option>
                        </select>
                    </div>
                </div>
                <span
                  class="navbar-burger burger"
                  data-target="navbarMenu"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </div>
              <div id="navbarMenu" class="navbar-menu">
                <div class="navbar-start">
                  <a v-for="(catalogue, key) in catalogues"
                     :key="key"
                     class="navbar-item"
                     :href="`#catalogue/${catalogue.id}`"
                     :class="{active: location === '#catalogue'}">
                    {{ $t(`${catalogue.name.toLowerCase()}_catalog`) }}
                  </a>
                  <slot name="navbar-start">
                  </slot>
                </div>
                <div class="navbar-end">
                  <div class="navbar-item">
                    <button onclick="window.open('https://www.drorto.hu/kapcsolat/')">
                        {{ $t('customer_service') }}
                    </button>
                    <button onclick="window.open('https://www.drorto.hu/online-idopont-keres/')">
                        {{ $t('appointment') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
        </nav>
        <!-- END NAV -->

        <div :class="{container: true, 'is-fluid': fluid, main: true}">
            <div class="section">
                <slot name="content"></slot>
            </div>
        </div>
        <footer class="footer">
            <div class="container is-fluid">
              <img src="./assets/footer-logo.png" />
              <div class="columns">
                <div class="column">
                  <p class="is-3">{{ $t('products') }}</p>
                  <a href="https://www.drorto.hu/egyedi-gyartasu-gyogycipok-katalogusa/" target="_blank">{{ $t('shoes') }}</a>
                  <a href="https://www.drorto.hu/talpbetetek-katalogusa/" target="_blank">{{ $t('insoles') }}</a>
                  <p>{{ $t('tools-for-foot-problems') }}</p>
                  <p>{{ $t('fasteners') }}</p>
                  <p>{{ $t('lower_limb_prostheses') }}</p>
                  <p>{{ $t('custom_made_orthoses') }}</p>
                  <p>{{ $t('walking_aids') }}</p>
                  <p>{{ $t('wheelchairs_pushchairs') }}</p>
                </div>
                <div class="column">
                  <p class="is-3">{{ $t('services') }}</p>
                  <p>{{ $t('personalized_services') }}</p>
                  <p>{{ $t('consultancy') }}</p>
                  <a href="https://www.drorto.hu/service/mozgasszervi-szuresek/" target="_blank">{{ $t('assessment') }}</a>
                  <p>{{ $t('ask_drorto') }}</p>
                  <a class="is-3" href="https://www.drorto.hu/rolunk/" target="_blank">{{ $t('about_us') }}</a>
                  <a href="https://www.drorto.hu/rolunk/" target="_blank">{{ $t('get_to_know_us') }}</a>
                  <a href="https://www.drorto.hu/allaslehetosegek/" target="_blank">{{ $t('career') }}</a>
                  <p>{{ $t('our_websites') }}</p>
                  <p>{{ $t('distributed_brands') }}</p>
                </div>
                <div class="column">
                  <a class="is-3" href="https://www.drorto.hu/dr-orto-szakuzletek/" target="_blank">{{ $t('specialty_stores') }}</a>
                  <a class="is-3" href="https://www.drorto.hu/blog/" target="_blank">{{ $t('magazine') }}</a>
                  <a class="is-3" href="https://www.drorto.hu/gyik/" target="_blank">{{ $t('faq') }}</a>
                  <a class="is-3" href="https://www.drorto.hu/impresszum/" target="_blank">{{ $t('impressum') }}</a>
                  <a class="is-3" href="https://www.facebook.com/drorto.hu" target="_blank"><img src="./assets/fb-logo.png" /></a>
                </div>
                <div class="column is-two-fifths">
                  <p class="is-3">{{
                          $t("newsletter.title")
                      }}</p>
                  <input type="text" :placeholder="$t('newsletter.name')" />
                  <input type="text" :placeholder="$t('newsletter.email')" />
                  <label class="checkbox">
                    <input type="checkbox" />
                      {{ $t('newsletter.privacy_policy') }}
                  </label>
                  <label class="checkbox">
                    <input type="checkbox" />
                      {{ $t('newsletter.gtc') }}
                  </label>
                  <button class="button">
                      {{ $t('newsletter.subscribe') }}
                  </button>
                </div>
              </div>
              <div class="line"></div>
              <p class="copyright">Copyright &copy; Dr Orto 2022</p>
            </div>
        </footer>
    </div>
</template>

<script>
    import "bulma";
    import './menu.scss';
    import "./content.scss";
    import {catalogueClient} from "@diabfoot/client-cache/index.mjs";

    export default {
        props: {
            fluid: Boolean
        },
        data() {
          return {
              catalogues: null,
              language: 'hu'
          }
        },
        async mounted() {
            this.catalogues = await catalogueClient.listCatalogues();
            this.language = localStorage.getItem('lang') ?? 'hu';
            //this.catalogues = this.catalogues.filter((c) => c.id !== "4")
            // Get all "navbar-burger" elements
            const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

            // Check if there are any navbar burgers
            if ($navbarBurgers.length > 0) {

                // Add a click event on each of them
                $navbarBurgers.forEach( el => {
                    el.addEventListener('click', () => {

                        // Get the target from the "data-target" attribute
                        const target = el.dataset.target;
                        const $target = document.getElementById(target);

                        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                        el.classList.toggle('is-active');
                        $target.classList.toggle('is-active');
                        $target.style.height = 'initial';
                    });
                });
            }
        },
        methods: {
            $t(key) {
                return this.$i18n.t(`scaffold.${key}`);
            },
            droorto() {
                window.open('https://drorto.hu/', '_blank');
            },
            onDropdown() {
              const languageDropdown = document.getElementById('language-dropdown');
              languageDropdown.classList.toggle('is-active');
            },
            selectLanguage(lang) {
              if(typeof lang === 'object') {
                  localStorage.setItem('lang', lang.target.value);
              } else {
                  localStorage.setItem('lang', lang);
              }
              const languageDropdown = document.getElementById('language-dropdown');
              languageDropdown.classList.remove('is-active');
              window.location.reload();
            }
        }
    }
</script>

<style>
</style>