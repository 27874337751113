<template>
    <div class="order">
        <loading v-if="loading"></loading>
        <div class="order-content" v-else>
            <div class="header-line">
                <h3>{{ $t('your_request') }}</h3>
                <div class="orange-line"></div>
                <button @click="resetStep">{{ $i18n.t('flow.restart') }}</button>
            </div>
            <div class="columns">
                <div class="column image-box">
                    <figure class="image" style="width: 50%;">
                        <product-svg-preview
                            :product-id="productId"
                            :customizations="customizations"
                            :applied-customizations="appliedColors"
                        ></product-svg-preview>
                    </figure>
                </div>
                <div class="column details">
                    <h4>{{ $t('selected_model') }}</h4>
                    <h2>{{ productName }}</h2>
                    <div class="columns">
                        <div class="column texts">
                            <h3>{{ $t('materials') }}</h3>

                            <div class="text" v-for="material of materials">
                                <p>{{ $i18n.t(`flow.colorer.parts.${material.name}`) }}:</p>
                                <label>{{ material.color }}</label>
                            </div>
                        </div>
                        <div class="column texts">
                            <h3>{{ $t('measurements_left') }}</h3>
                            <div class="text" v-for="size of leftSizes">
                                <p>{{ $t(`sizes.${size.id.split('-')[1]}`) }}:</p>
                                <label>{{ size.size }} mm</label>
                            </div>

                            <h3>{{ $t('measurements_right') }}</h3>
                            <div class="text" v-for="size of rightSizes">
                                <p>{{ $t(`sizes.${size.id.split('-')[1]}`) }}:</p>
                                <label>{{ size.size }} mm</label>
                            </div>

                            <div class="text">
                                <p>{{ $t('insole') }}:</p>
                                <label>{{ insole.name }}</label>
                            </div>
                            <div class="text">
                                <p>{{ $t('illness') }}:</p>
                                <div
                                    class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
                                >
                                    <label v-for="answer of illnessAnswers">
                                        {{ answer.label }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <label>{{ $t('bottom_text') }}</label
                    >
                </div>
            </div>
            <div class="is-flex is-justify-content-center is-align-items-center button-container">
                <button @click="back" class="back-button">{{ $i18n.t('flow.back') }}</button>
                <button @click="onFinishOrder" class="finish-button">{{ $t('finalize_request') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import "./order.scss";

import ProductSvgPreview from "@diabfoot/component-product-svg-preview";

import { catalogueClient, customizerClient } from "@diabfoot/client-cache";
import Loading from "@diabfoot/component-loading";

export default {
    components: {
        ProductSvgPreview,
        Loading
    },
    props: {
        productId: String,
        appliedColors: Array,
        appliedSizes: Array,
        appliedOptions: Array,
        illnessAnswers: Array,
        productName: String,
    },
    data() {
        return {
            materials: [],
            leftSizes: [],
            rightSizes: [],
            insole: null,
            customizations: [],
            loading: true,
        };
    },
    async mounted() {
        const customizations = await customizerClient.listCustomizations({
            productId: this.productId,
        });
        this.customizations = customizations;

        this.materials = this.appliedColors
            .map((color) => {
                const colorCustomization = customizations.find(
                    (c) => c.id === color.id
                );
                for (let colorGroup of colorCustomization.color.groups) {
                    const foundColor = colorGroup.colors.find(
                        (c) => c.id === color.color
                    );
                    if (foundColor) {
                        return {
                            name: colorCustomization.name,
                            color: foundColor.name,
                        };
                    }
                }
            })
            .filter((c) => !!c);

        const allSizes = this.appliedSizes.map((size) => {
            const sizeCustomization = customizations.find(
                (c) => c.id === size.customizationId
            );
            return {
                name: sizeCustomization.name,
                size: size.size,
                id: sizeCustomization.id,
            };
        });

        this.leftSizes = allSizes.filter((s) => s.id.indexOf("-left") !== -1);
        this.rightSizes = allSizes.filter((s) => s.id.indexOf("-right") !== -1);

        const insoleOption = this.appliedOptions[0];
        const insoleCustomization = customizations.find(
            (c) => c.type === "OPTION_IMAGE"
        );
        this.insole = insoleCustomization.options.find(
            (o) => o.id === insoleOption.optionId
        );

        this.loading = false;
    },
    methods: {
        onFinishOrder() {
            this.$emit('finish');
        },
        back() {
            this.$emit('back');
        },
        resetStep() {
            this.$emit('reset');
        },
        $t(key) {
            return this.$i18n.t(`flow.order.${key}`);
        }
    }
};
</script>

<style></style>
