import getOpt from "@diabfoot/get-opt";

export class CatalogueClient {
    constructor(opts) {
        this.graphqlClient = getOpt(opts, "graphqlClient");
    }

    async getProductFamily(id, catalog) {
        const response = await this.graphqlClient.query(
            `
                query GetProductFamily($id: ID!, $catalog: String!) {
                    productFamily {
                        byId(id: $id, catalog: $catalog) {
                            id
                            name
                            description
                            imageUrl
                            moreImages
                        }
                    }
                }
            `,
            {
                id,
                catalog
            }
        );

        return response.data.productFamily.byId;
    }

    async listProductFamilies(opts) {

        const { productFilters } = opts;

        if (productFilters && Array.isArray(productFilters)) {
            const response = await this.graphqlClient.query(
                `query FamiliesByProductFilters($filters: [FilterInput!]!) {
                    productFamily {
                        byProductFilters(productFilters: $filters) {
                            id
                            name
                            description
                            imageUrl
                        }
                    }
                }`,
                {
                    filters: productFilters
                }
            );
            return response.data.productFamily.byProductFilters;
        } else {
            const response = await this.graphqlClient.query(
                `query ListProductFamilies {
                    productFamily {
                        list {
                            id
                            name
                            description
                            imageUrl
                            moreImages
                        }
                    }
                }`
            );
            return response.data.productFamily.list;
        }

    }

    async listProducts(opts) {

        const filters = getOpt(opts, "filters");
        const sort = getOpt(opts, "sort");
        const page = getOpt(opts, "page");
        const count = getOpt(opts, "count");

        const response = await this.graphqlClient.query(
            `
                query ListProducts(
                    $filters: [FilterInput!]!,
                    $sortBy: String!,
                    $sortDirection: String!,
                    $page: Int!,
                    $count: Int!
                ) {
                    product {
                        list(input: {
                            filters: $filters,
                            sortBy: $sortBy,
                            direction: $sortDirection,
                            page: $page,
                            count: $count
                        }) {
                            id
                            name
                            sku
                            imageUrl
                            filterValues {
                                id
                                values
                            }
                        }
                    }
                }
            `,
            {
                filters,
                sortBy: (sort || {}).by || "name",
                sortDirection: (sort || {}).direction || "asc",
                page,
                count
            }
        );

        return response.data.product.list;

    }

    async countProducts(opts) {

        const filters = getOpt(opts, "filters");
        const sort = getOpt(opts, "sort");
        const page = getOpt(opts, "page");
        const count = getOpt(opts, "count");

        const response = await this.graphqlClient.query(
            `
                query ListProducts(
                    $filters: [FilterInput!]!,
                    $sortBy: String!,
                    $sortDirection: String!,
                    $page: Int!,
                    $count: Int!
                ) {
                    product {
                        total(input: {
                            filters: $filters,
                            sortBy: $sortBy,
                            direction: $sortDirection,
                            page: $page,
                            count: $count
                        })
                    }
                }
            `,
            {
                filters,
                sortBy: (sort || {}).by || "name",
                sortDirection: (sort || {}).direction || "asc",
                page,
                count
            }
        );

        return response.data.product.total;
    }

    async getProduct(id) {
        const response = await this.graphqlClient.query(
            `
                query GetProduct($id: ID!) {
                    product {
                        byId(id: $id) {
                            id
                            name
                            sku
                            imageUrl
                            filterValues {
                                id
                                value
                                values
                            }
                            family {
                                id
                                name
                                description
                            }
                            categories {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            {
                id
            }
        );

        return response.data.product.byId;
    }

    async listCatalogues() {
        const response = await this.graphqlClient.query(
            `query listCatalogues {
                productCatalog {
                    list {
                        id,
                        name
                    }
                }
            }`,
        );

        return response.data.productCatalog.list;
    }

    async getCalatogue(id) {
        const response = await this.graphqlClient.query(
            `query getCalatogue($id: ID!) {
                productCatalog {
                    byId(id: $id) {
                        name
                    }
                }
            }`,
            {
                id
            }
        );

        return response.data.productCatalog.byId;
    }
}