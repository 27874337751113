<template>
    <div class="item-grid">
        <div class="columns is-multiline">
            <div
                class="column is-12-mobile is-6-tablet is-4-desktop"
                v-for="(item, index) in items"
                :key="index"
            >
                <catalogue-item :item="item" :index="index"></catalogue-item>
            </div>
        </div>
    </div>
</template>

<script>
    import CatalogueItem from "@diabfoot/component-catalogue-item";

    export default {
        components: {CatalogueItem},
        props: {
            items: Array
        }
    }
</script>

<style>
</style>