import LandingPage from "@diabfoot/component-landing-page"
import Catalogue from "@diabfoot/component-catalogue"
import MyBasket from "@diabfoot/component-my-basket"
import Order from "@diabfoot/component-order"
import Flow from "@diabfoot/component-flow";
import ContactForm from "@diabfoot/component-contact-form";

export default [
    {
        path: "landing-page",
        component: LandingPage
    },
    {
        path: "",
        component: LandingPage
    },
    {
        path: /landing-page\/(.*)/,
        component: LandingPage
    },
    {
        path: /catalogue\/(.*)/,
        component: Catalogue
    },
    {
        path: "my-basket",
        component: MyBasket
    },
    {
        path: "order",
        component: Order
    },
    {
        path: /flow\/(.*)\/(.*)/,
        component: Flow
    },
    {
        path: "thank-you",
        component: ContactForm
    }
]