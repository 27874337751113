import getOpt from "@diabfoot/get-opt";

export class ProductFilterClient {
    constructor(opts) {
        this.graphqlClient = getOpt(opts, "graphqlClient");
    }

    async byId(id) {
        const response = await this.graphqlClient.query(
            `query FiltersById($id: ID!) {
                productFilter {
                    byId(id: $id) {
                        id
                        name
                        options {
                            id
                            name
                        }
                    }
                }
            }`,
            {
                id
            }
        );
        return response.data.productFilter.byId;
    }

    async list() {
        const response = await this.graphqlClient.query(
            `query {
                productFilter {
                    list {
                        id
                        name
                        options {
                            id
                            name
                        }
                    }
                }
            }`
        );

        return response.data.productFilter.list;
    }
}