<template>
    <a class="color-swatch" :style="swatchStyle" @click="onSelected">
        <tooltip>{{ name }}</tooltip>
    </a>
</template>

<script>
import Tooltip from "@diabfoot/component-tooltip";
export default {
    components: {Tooltip},
    props: {
        colorId: String,
        name: String,
        rgb: String,
        imageUrl: String
    },
    methods: {
        async onSelected() {
            this.$emit("selected", this.colorId);
        }
    },
    computed: {
        swatchStyle() {
            if(this.imageUrl) {
                return {
                    backgroundImage: `url(${this.imageUrl}?tr=r-max,w-40,h-40)`
                };
            } else {
                return {
                    backgroundColor: this.rgb
                };
            }
        }
    }
}
</script>

<style>
.color-swatch {
    width: 40px;
    height: 40px;
    margin: 8px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0px 8px;;
}
</style>