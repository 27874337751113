<template>
    <div class="product-chooser">
        <loading v-if="loading"></loading>

        <div v-else>
            <div class="details">
                <div class="images">
                    <carousel :settings="settings">
                        <slide
                            v-for="slide in productFamily.moreImages"
                            :key="slide"
                        >
                            <figure class="image is-fullwidth">
                                <img :src="slide" />
                            </figure>
                        </slide>

                        <template #addons>
                            <navigation />
                        </template>
                    </carousel>
                </div>
                <div class="text-content">
                    <h4>{{ $t('your_choice') }}</h4>
                    <ul class="illness-answers-list">
                        <li v-for="answer of illnessAnswers">{{ answer.label }}</li>
                    </ul>
                    <h2>
                        {{ $t('meet_title', productFamily.name) }}
                        <!--Meet the {{ productFamily.name }} model line-->
                    </h2>
                    <p>{{ $t(`${productFamily.name.toLowerCase().split(' ').join('_')}_description`) }}</p>
                    <div class="tags product-details-tags">
                        <!--span class="tag disabled">Kismamáknak</span>-->
                        <span class="tag" v-for="category in categories" :key="category.id">{{ category.name }}</span>
<!--                        <span class="tag">{{ $i18n.t('product-filters.standing') }}</span>
                        <span class="tag">{{ $i18n.t('product-filters.diabetes') }}</span>-->
                    </div>
                    <span class="icon-text info-text">
                        <span class="icon">
                            <i class="info-icon"></i>
                        </span>
                        <span
                            >{{
                                $t('custom_shoes_text')
                            }}</span
                        >
                    </span>
                </div>
            </div>

            <div class="product-items">
                <div class="header-line">
                    <div class="orange-line"></div>
                </div>
                <div class="columns is-multiline">
                    <div
                        v-for="(product, key) in products"
                        :key="key"
                        class="column is-3"
                    >
                        <card-item image class="product-card-item">
                            <template #card-image>
                                <figure class="image">
                                    <img :src="product.imageUrl" />
                                </figure>
                            </template>
                            <template #card-content>
                                <h6 class="title is-6">{{ product.name }}</h6>
                                <button @click="onChooseProduct(product)">
                                    {{ $t('i_personalize_it') }}
                                </button>
                            </template>
                        </card-item>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "./product-chooser.scss";
import "vue3-carousel/dist/carousel.css";
import CardItem from "@diabfoot/component-card/CardItem";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { catalogueClient, productFilterClient } from "@diabfoot/client-cache";
import Loading from "@diabfoot/component-loading/Loading";

export default {
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        CardItem,
        Loading
    },
    data() {
        return {
            settings: {
                itemsToShow: 1,
                snapAlign: "center",
            },
            productFamily: null,
            loading: true,
            categories: null
        };
    },
    props: {
        illnessAnswers: Array,
    },
    methods: {
        async onChooseProduct(product) {
            this.$emit("choose-product", product);
        },
        $t(key, parameter = null) {
            return this.$i18n.t(`product-details.${key}`, parameter);
        }
    },
    async mounted() {
        const productFamilyId = this.route.groups[1];
        const productCatalogId = this.route.groups[2];
        const productFamily = await catalogueClient.getProductFamily(
            productFamilyId,
            productCatalogId
        );
        const products = await catalogueClient.listProducts({
            filters: [
                { id: "family", values: [productFamilyId] },
                { id: "catalog", values: [productCatalogId] }
            ],
            sort: {
                by: "name",
                direction: "asc",
            },
            page: 0,
            count: 1000,
        });

        this.categories = await productFilterClient.list();
        this.categories = this.categories.filter(f => f.id === "categories")[0].options
        let categoryIds = [];
        products.forEach(p => {
            const filterIds = p.filterValues.find(f => f.id === "categories").values;
            filterIds.forEach(fi => {
                if(!categoryIds.includes(fi)) {
                    categoryIds.push(this.categories.find(c => c.id === fi).id)
                }
            })
        })

        this.categories = this.categories.filter(f => categoryIds.includes(f.id));

        this.productFamily = productFamily;
        this.products = products;
        this.loading = false;
    },
    inject: ["route"],
};
</script>

<style scoped>
.illness-answers-list {
    margin-left: 25px;
    list-style-type: disc;
}
</style>
