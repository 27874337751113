
export default class CatalogueFilterClient {

    constructor() {
        this.state = [];
    }

    setState(state) {
        this.state = state;
    }

    getState() {
        return this.state;
    }

}