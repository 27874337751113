<template>
    <span class="tooltip">
        <slot></slot>
    </span>
</template>

<script>
export default {
    props: {
        value: String
    }
}
</script>

<style>
span.tooltip {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #999;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    display: none;
    transition: opacity 0.2s ease-in-out;
    width: max-content;
    z-index: 9;
    font-family: 'Poppins';
    line-height: 20px;
}

a:hover > span.tooltip {
    display: block;
}
</style>