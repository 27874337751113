<template>
    <div class="catalogue-infinite-scroller" ref="scroller"></div>
</template>

<script>
    export default {
        mounted() {

            const intersectionObserver = new IntersectionObserver(this.onIntersectionObserverEvent, {
                root: null,
                rootMargin: "0px",
                threshold: 1.0
            });
            intersectionObserver.observe(this.$refs.scroller);

        },
        data() {
            return {
                mountTime: new Date().getTime()
            }
        },
        methods: {
            async onIntersectionObserverEvent() {
                const now = new Date().getTime();
                if (now - this.mountTime > 50) {
                    this.$emit("load");
                    this.mountTime = new Date().getTime();
                }
            }
        }
    }
</script>

<style>
</style>