<template>
    <div class="colored-part-handle-div">
        <a :class="{'colored-part-handle': true, 'is-selected': isSelected}"> <!--:style="{top: `${topPx}px`, left: `${leftPx}px`}"-->
    <!--        <tooltip>
                {{ name }}
            </tooltip>-->
        </a>
        <label>{{ $t(name) }}</label>
    </div>
</template>

<script>
import Tooltip from "@diabfoot/component-tooltip";
import "./color-part-handle.scss";
export default {
    components: {
        Tooltip
    },
    methods: {
        async onClick() {
            this.$emit("selected", this.id);
        },
        $t(key) {
            return this.$i18n.t(`flow.colorer.parts.${key}`);
        }
    },
    props: {
        element: Object,
        id: String,
        name: String,
        isSelected: Boolean,
        svgBoundingBox: Object
    },
    computed: {
        topPx() {
            const rect = this.element.getBoundingClientRect();
            const center = (rect.y - this.svgBoundingBox.y) + rect.height / 2;
            return center + 40;
        },
        leftPx() {
            const rect = this.element.getBoundingClientRect();
            const center = (rect.x - this.svgBoundingBox.x) + rect.width / 2;
            return center + 36;
        }
    },
    async mounted() {
        console.log(this.element);
        console.log(this.element.offsetTop);
        console.log(this.element.offsetLeft);
    }

}
</script>

<style>
</style>