<template>
  <div class="custom-loader-overlay">
    <div class="custom-loader"></div>
  </div>
</template>

<script>
import './loading.scss';
export default {

}
</script>

<style>

</style>