<template>
    <div class="colored-part-chooser">
        <div class="part-preview-container">
            <product-svg-preview :product-id="productId"
                                    :customizations="customizations"
                                    :applied-customizations="appliedCustomizations"
                                    ref="productSvgPreview"
                                    @loaded="onSVGPreviewLoaded"
            ></product-svg-preview>
        </div>

        <div class="part-handle-container">
            <colored-part-handle v-for="handle of partHandles" :is-selected="handle.id === selectedCustomizationId" :id="handle.id" :element="handle.element" :name="handle.name" :svg-bounding-box="svgPreviewElementBoundigBox" @selected="onPartSelected"></colored-part-handle>
        </div>
    </div>
</template>

<script>
import ProductSvgPreview from "@diabfoot/component-product-svg-preview";
import ColoredPartHandle from "@diabfoot/component-colored-part-handle";
export default {
    components: {
        ProductSvgPreview,
        ColoredPartHandle,
    },
    data() {
        return {
            partHandles: [],
            svgPreviewElementBoundigBox: null
        }
    },
    props: {
        productId: String,
        customizations: Array,
        appliedCustomizations: Array,
        selectedCustomizationId: String
    },
    async mounted() {
        window.addEventListener('resize', this.getDimensions);
    },
    unmounted() {
        window.removeEventListener('resize', this.getDimensions);
    },
    methods: {
        async onSVGPreviewLoaded() {

            this.partHandles = [];

            const svgPreviewElement = this.$refs.productSvgPreview.$el;
            this.svgPreviewElementBoundigBox = svgPreviewElement.getBoundingClientRect();
            this.addEventListeners(svgPreviewElement);

            for(let customization of this.customizations) {
                if(customization.type !== "COLOR") {
                    continue;
                }
                const customizationColor = customization.color;
                if(customizationColor && customizationColor.selector) {
                    const customizationElement = svgPreviewElement.querySelector(`svg #${customizationColor.selector}`);
                    if(customizationElement) {
                        this.partHandles = [...this.partHandles, {
                            id: customization.id,
                            element: customizationElement,
                            name: customization.name,
                            order: customization.order
                        }];
                    }
                }
            }

            this.partHandles.sort((a,b) => a.order - b.order);

        },
        getPathChildren(element) {
            let paths = [];
            for (let child of element.children) {
                if (child instanceof SVGGElement) {
                    let retval = this.getPathChildren(child);
                    retval.forEach((v) => {
                        paths.push(v);
                    });
                } else if(child instanceof SVGPathElement) {
                    paths.push(child)
                }
            }
            return paths;
        },
        addEventListeners(svgPreviewElement) {
            const svg = svgPreviewElement.querySelector('svg');
            const allSelector = svg.querySelectorAll('svg > g');
            allSelector.forEach((element) => {
                if (element.id.toLowerCase() !== 'tűzés' && element.id.toLowerCase() !== 'díszítés') {
                    const matchingG = this.customizations.find((c) => c.color.selector === element.id);
                    const paths = this.getPathChildren(element);
                    paths.forEach((v) => {
                        v.style.cursor = 'pointer'
                        v.addEventListener('click', () => {
                            console.log(allSelector);
                            const otherSelectors = Array.from(allSelector).filter((e) => e.id !== element.id);
                            otherSelectors.forEach((e) => {
                                const otherSelectorPaths = this.getPathChildren(e);
                                otherSelectorPaths.forEach((p) => {
                                    p.style.stroke = 'black';
                                    p.style.strokeWidth = '1px';
                                });
                            });
                            paths.forEach(p => {
                                p.style.stroke = '#738FC6';
                                p.style.strokeWidth = '3px';
                            })
                            this.$emit('selected-part-changed', matchingG.id);
                        });
                    })
                }
            }, false);
        },
        getDimensions() {
            const svgPreviewElement = this.$refs.productSvgPreview.$el;
            this.svgPreviewElementBoundigBox = svgPreviewElement.getBoundingClientRect();
        },
        async onPartSelected(id) {
            console.log("onPartSelected", id);
            this.$emit("selected-part-changed", id);
        }
    }
}
</script>

<style>
.colored-part-chooser {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
}

.part-handle-container {
    top: 0;
    left: 0;
    width: 200px;
    margin-left: 20px;
}

.part-preview-container {
    position: relative;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 768px) {
    .part-handle-container {
        display: none;
    }
    .colored-part-chooser {
        flex-direction: column;
    }
}

</style>