export class CustomizerClient {
    constructor({ graphqlClient }) {
        this.graphqlClient = graphqlClient;
    }

    async listCustomizations({
        productId
    }) {
        const response = await this.graphqlClient.query(
            `query ListCustomizations($productId: ID!) {
                product {
                    byId(id: $productId) {
                        customizations {
                            id
                            name
                            type
                            order
                            color {
                                selector
                                operation_group
                                nature
                                groups {
                                    group
                                    colors {
                                        id
                                        name
                                        rgb
                                        imageUrl
                                    }
                                }
                            }
                            options {
                                id
                                imageUrl
                                name
                                description
                            }
                            size {
                                min
                                max
                                unit
                            }
                        }
                    }
                }
            }`,
            {
                productId
            }
        );

        return response.data.product.byId.customizations;
    }

    async getProductSVG({ productId }) {
        const response = await this.graphqlClient.query(
            `query GetProductSVG($productId: ID!) {
                product {
                    byId(id: $productId) {
                        svg
                    }
                }
            }`,
            {
                productId
            }
        );

        return response.data.product.byId.svg;
    }

}
