<template>
    <scaffold>
        <template #content>
            <a href="#catalogue">Back to catalogue</a>
            <p>This is my basket</p>
            <ul>
                <li v-for="(item, index) in items" :key="index">
                    <div class="level">
                        <span>{{ item.id }}</span>
                        <button class="button is-danger" @click="() => onRemoveFromBasket(item)">Remove</button>
                    </div>
                    
                </li>
            </ul>
            <button class="button is-danger" @click="onClearBasket">
                Clear basket
            </button>
        </template>
    </scaffold>
</template>

<script>
    import Scaffold from "@diabfoot/component-scaffold";
    import { basketClient } from "@diabfoot/client-cache";

    export default {
        components: { Scaffold },
        async mounted() {

            try {

                const items = await basketClient.loadItems();
                this.items = items;
                this.loading = false;

            } catch (err) {
                this.error = err;
                this.loading = false;
            }

        },
        data() {
            return {
                items: [],
                loading: true,
                error: null
            }
        },
        methods: {
            async onClearBasket() {
                this.loading = true;
                try {

                    await basketClient.clearBasket();
                    this.items = await basketClient.loadItems();
                    this.loading = false;
                } catch (err) {
                    this.error = err;
                    this.loading = false;
                }

            },

            async onRemoveFromBasket(item) {
                this.loading = true;
                try {

                    await basketClient.removeItem(item.id);
                    this.items = await basketClient.loadItems();
                    this.loading = false;
                } catch (err) {
                    this.error = err;
                    this.loading = false;
                }

            }
        }
    }
</script>

<style>
</style>