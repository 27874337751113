{
  "scaffold": {
    "configurator_header": "Diabfoot congfigurator",
    "drorto_website": "Dr. Orto website",
    "customer_service": "Customer service",
    "language": "Language",
    "english": "English",
    "hungarian": "Hungarian",
    "női_catalog": "Custom-made women's shoes",
    "gyerek_catalog": "Custom-made kids shoes",
    "férfi_catalog": "Custom-made men's shoes",
    "trend_catalog": "Custom-made trend shoes",
    "appointment": "Book an appointment",
    "products": "Products",
    "faq": "FAQ",
    "specialty_stores": "Specialty stores",
    "magazine": "Magazine",
    "impressum": "Impressum",
    "shoes": "Shoes",
    "insoles": "Insoles",
    "tools-for-foot-problems": "Tools for foot problems",
    "fasteners": "Fasteners, compression products",
    "lower_limb_prostheses": "Lower limb prostheses",
    "custom_made_orthoses": "Custom - made orthoses",
    "walking_aids": "Walking aids",
    "wheelchairs_pushchairs": "Wheelchairs, pushchairs",
    "services": "Services",
    "personalized_services": "Personalized services",
    "consultancy": "Rehabilitation and orthopedic specialist consultancy",
    "assessment": "Screening and condition assessment",
    "ask_drorto": "Ask Dr. Orto",
    "about_us": "About us",
    "get_to_know_us": "Get to know us",
    "career": "Career",
    "our_websites": "Our websites",
    "distributed_brands": "Distributed brands",
    "newsletter": {
      "title": "Find out what's new first hand! Subscribe to our newsletter!",
      "name": "Name",
      "email": "E-mail address",
      "privacy_policy": "I accept the Privacy Policy.",
      "gtc": "I accept the Terms and Conditions",
      "subscribe": "Subscribe"
    }
  },
  "landing-page": {
    "custom_shoes_header": "Why do we need custom-made shoes?",
    "custom_shoes_text": "Regular shoes are made for “average” feet and follow recent fashion. Designers and sellers are confident that design will “carry” the product and they’ll find many “feet” which will conform to the footwear for the sake of fashion. Also, the fashion industry pays no attention to the support of the sole. As a result, with the passing years, more and more people are suffering from foot damage caused by wrong shoes.",
    "custom_shoes_button": "Create your own custom-made shoes",
    "custom_womens_shoes": "I want a custom-made women's shoe",
    "custom_mens_shoes": "I want a custom-made men's shoe",
    "custom_kids_shoes": "I want custom-made kids' shoes",
    "custom_trend_shoes": "I want custom-made trend shoes",
    "how_will_you_have_a_custom_shoe": "How will you have a custom-made shoe?",
    "details": "Details",
    "flow": {
      "1": "Select the model line you like the most.",
      "2": "Use our shoe configurator and adjust your foot parameters, lesions or illnesses.",
      "3": "Assemble the color and material variations of your choice, pick an insole, then enter the measurements of your foot.",
      "4": "Finalize your order and wait for our customer service to contact you, in order to book an appointment.",
      "5": "At the appointment in our shop we well precisely measure your foot (Size, condition, possible lesions).",
      "6": "Our experts prepare your custom-made shoes.",
      "7": "At an appointment you will try the shoes on. If all goes well, you’re the happy owner of your custom-made shoes."
    }
  },
  "product-details": {
    "meet_title": "Meet the %s% model line",
    "your_choice": "Your choice",
    "i_personalize_it": "I personalize it",
    "details": "Details",
    "custom_shoes_text": "Our products are entirely custom-made for your feet. After sizing, our experts create your footwear fully based on your needs and symptoms.",
    "lisbon_b3_15_description":  "Lisbon B3 is a model line with a special, high heel counter suitable for step-insoles and custom-made insoles as well. Its base lines feature a number of combinations, therefore different members of this model line can meet any expectation for usage, and any design request. For those with bigger bunions, hammertoes or advanced diabetic foot problems, we strongly recommend all versions of this line.",
    "lima_15_description" :  "Lima is one of our most popular and complex model lines. Its baselines feature a number of combinations, therefore different members of the model line can meet any expectation for usage and any design request. For those with bigger bunions, hammertoes or advanced diabetic foot problems, we strongly recommend all versions of this line",
    "monaco_15_description":  "With its sharp yet rounded shape, simple or boldly composed color scheme, this model can be a great addition to your warderobe. You can see from far how comfortable these shoes are. Available with laces or velcro fastening, with a heel height of 15 mm, with a welted- or a cupsole. Excellent for protecting diabetic foot and can be used with a custom-made insole.",
    "osaka_15_description":  "Youthful trainers, which would look good in any shoe store. Available with laces or velcro fastening, with a heel height of 15 mm and with a casual sole. For an even trendier look, you can order them with a cupsole! Can be used with a custom-made insole, also suitable for protecting diabetic foot.",
    "torino_15_description":  "Low-top ankle trainers with a heel height of 15 mm, the short vamp and the so-called led-forward throat ensures that the shoes can be put on easily. The unique color scheme of the decorative side strap and the heel counter with a pull tab makes this model trendy and youthful. Also available in winter style with fur lining. The bottom part is a welted sole or a cupsole reinforced with seams.",
    "riga_15_description":  "Comfortable boots for wintertime, or even for hiking. Their soft, lined collar part ensures that your feet won’t get cold, but for the autumn months you can order one with a thinner lining. For those with foot deformations, bunions or hammertoes, these shoes can be used with a custom-made insole. Ideal for protecting and treating diabetic foot.",
    "oxford_15_description":  "Classic, elegant shoes made special by a perforated toe cap. Ideal for protecting diabetic foot with its deep design, and looks like a shoe from the 1920s. Can be used with a custom-made insole and ideal in case of minor foot deformities. ",
    "bira_15_description":  "Whether with velcro fastening or laces, Bira makes a perfect transition between trainers and street shoes. It can be a popular shoe among diabetic customers. Its final look is largely defined by choice of material. The version with laces and trendy colors is especially sporty and youthful; while the one with velcro can be a good choice for the elderly. You can also choose this footwear in case of bunions and hammertoes.",
    "bilbao_15_description":  "Bilbao makes a perfect transition between trainers and street shoes. Because of its tongue, wider opening and velcro fastening, it can be a popular shoe among diabetic customers. Its final look is largely defined by choice of material. It’s especially sporty and youthful with trendy colors; while the velcro fastening can be a good solution for the elderly. You can also choose this footwear in case of bunions and hammertoes.",
    "oslo_15_description":  "A model with high neck, laces or velcro fastening, which doesn’t need to be spared! Its design makes it incredibly easy to put-on. Can be ordered with welted sole or cupsole. Can be worn with diabetic foot, advanced bunion and hammertoe, can be used with a custom-made insole.",
    "berlin_15_description":  "This model comes with a toe box, decorative strap on the throat and makes a perfect transition between trainers and street shoes. Its final look is largely defined by choice of material and material combination. We can create an especially sporty, youthful model with trendy colors and a cupsole.",
    "astro_15_description":  "Skate style high neck shoes with laces or velcro fastening. The led-forward laces which bring the vamp and neck together, ensure that you can easily put the shoes on, the spongy collar part ensures the trendy sporty look. Proper support of the side of the foot is ensured by the led-forward heel counter, the closure and firm fastening of the upper part is ensured by the well-adhesive velcro with three oval rings, or by laces. The design of the bottom is a jagged shape with different types of welts, or a cupsole stiffened with seams.",
    "bahama_15_description":  "Sandals with a stable sole, which are perfect for sightseeing and which support the side of the foot as well. The custom-made insole blends in with the model perfectly, can be worn with such insoles too. Thanks to the detachable heel strap, it can be transformed into an indoor shoe in a minute. Its design without the heel strap ensures that you can easily put them on at home as well.",
    "miami_15_description":  "Sporty, modern sandal shoes with velcro fastening and with a minimalist design. Can be ordered with a cupsole as well, which borrows a youthful look to the footwear. Can be worn with a custom-made insole. For protecting diabetic foot, we recommend the version with a not splitted toe box..",
    "macao_15_description":  "Sporty, modern sandal shoes with velcro fastening and with a minimalist design. Can be ordered with a cupsole as well, which borrows a youthful look to the footwear. Can be worn with a custom-made insole and  recommended for protecting diabetic foot too.",
    "ciprus_15_description":  "Sporty sandal shoes, which are the perfect choice thanks to their velcro fastening, if you need to be out the door quickly in the morning. They can be made with a normal vamp for spring, ventilating perforation for summer or even with a big oval perforation. Their sole can be decorated with a welt, can be a casual sole with a heel height of 15 mm, or a slightly more modern cupsole. Especially recommended for protecting diabetic foot, for custom-made insoles and bunions, hammer-toes as well.",
    "corsica_15_description":  "The real clogs which can even have a heel strap. This model can be made with a normal vamp, decorative performation, can have a welted sole or a cupsole with a heel height of 15 mm. Can be created with an almost endless color combination. Can be used with a custom-made insole, thanks to a stretchy elastic panel inside and a technical trick, they’re an excellent choice for those with swollen feet.",
    "sofia_25_description":  "Ballerina shoes with cross straps for running errands insummer. Can even be elegant with their tapered toe shape, heel height of 25 mm and matching color combination. Recommended in case of small bunions and minor hammertoes, also can be worn with a custom-made insole.",
    "elba_15_description":  "Special, sporty sandals which can be worn with a custom-made insole and ankle-foot orthosis thanks to their closed heel counter design. The design of the straps ensures that you can easily slip into your sandals, also ensures firm fastening while walking. They look best with bold color combinations.",
    "adana_25_description":  "Classic, elegant shoes with a heel height of 25 mm, which are perfect for all ages with a proper choice of color and materials. The model is suitable for protecting diabetic foot, recommended in case of minor bunions and hammertoes as well. ",
    "bern_25_description":  "Shoes with laces and a slightly elevated heel, the uniqueness of which can be further increased by your own color and material choice on the throat. Don’t worry even if you have bunions and hammertoes, you will find this model of ours comfortable.",
    "birakan_25_description":  "Sporty and elegant shoes with laces or velcro fastening. The perfect color combinations of the throat and spongy collar part, and the milled sole with a heel height of 25 mm and a welt decoration, borrow a harmonic and feminine look to the model, which can be worn with minor bunion and hammertoe as well. ",
    "corfu_15_description":  "Open slippers with a stretchy elastic panel, which can be worn with a custom-made insole. The welted sole with a heel height of 15 mm and the wide velcros both provide stability. Can be worn with minor bunions and hammertoes as well. Thanks to the adjustable straps, they overcome the obstacle of swollen feet too.",
    "marino_25_description":  "Whether with lining for winter or thinner for summer, these ankle shoes come in handy at any time. They’re made with a heel height of 25 mm and a feminine, welted sole. They have a moderately elegant closing with a buckle; hence they suit any occasion. Recommended for minor foot deformations, can be worn with a custom-made insole.",
    "alta_25_description":  "Youthful ankle shoes with laces. Their collar part is optional: can be an elastic band snugly to the feet or spongy soft leather. For spring they’re sporty and fun, while with a warm sole they make perfect ankle shoes for autumn and winter. The model’s heel height is 25 mm and it’s made with a welted sole with optional colors.",
    "bonn_25_description":  "The modern Bonn is a lace-up design, which has an extra depth and not only in terms of possibilities. Thanks to its design it’s perfect for custom-made insoles, can be worn even with minor foot deformities. They’re made with a welted sole with a heel height of 25 mm; and the divided vamp gives plenty of room for color and material combinations. ",
    "dover_25_description":  "Trendy, feminine ankle shoes with a slightly elevated heel. The elegant alignment and elastic band snugly to the feet makes this model special. The vamp which can be assembled from multiple parts, and the throat provide an excellent opportunity for you to design perfectly unique shoes playing with colors and materials. Can be worn with a custom-made insole but not recommended in case of bigger bunions and hammertoes. ",
    "kairo_15_description":  "Sandals with spongy collar design and velcro fastening, which are perfect for every day. The chosen sole defines whether the shoes will be sporty or elegant. Can be worn with a custom-made insole, recommended for normal feet and in case of a small bunions.",
    "madison_b3_15_description":  "A model line made with a high heel counter based on the example of classic, casual shoes. Can be worn with step-insoles and custom-made insoles as well. The model variations are made with laces or velcro fastening, traditional, welted, or sporty cupsole. Its design is suitable for diabetic, ulcerative foot.",
    "paris_15_description":  "Its biggest feature is the short toe box and led-forward throat which ensures that you can easily put your shoes on. The cupsole stiffened with seams ensures the sporty look. The side of the neck is decorated with stitching, while the throat is decorated with a strip which gives room for combinations. Its closure opens the door to tying the shoes in a trendy way.",
    "hodor_15_description":  "The Hodor model line has a special closing feature, its tongue can be folded down to the toe box, and its big opening ensures that you can easily put your shoes on. Especially recommended for those struggling with advanced diabetic foot problems.",
    "emma_25_description":  "Classic orthopedic high-neck shoes with laces or velcro fastening. Their spring-summer version called “Veronika” is decorated with a ventilating perforation and stitching on the vamp and neck. Their autumn-winter version called “Emma” can be ordered without any perforation on the neck, and with a thin laminated lining. This model line has a heel height of 25 mm, therefore comfortable and feminine. Suitable in case of foot deformities, bunions, and hammertoes as well, can be worn with a custom-made insole. Ideal for protecting and treating diabetic foot.",
    "izabella_15_description":  "Orthopedic high neck shoes with laces or velcro fastening, the led-forward neck of which ensures that you can put your shoes on easily. The model line has a heel height of 15 mm, is made with a comfortable, spongy collar which wraps around the neck. The spring-summer version called “Izabella” has a ventilating perforation and decorative stitching on the throat and neck. Can be worn with bunions and hammertoes, suitable for custom-made insoles. Ideal for protecting and treating diabetic foot.",
    "rita_15_description":  "Sandals with an open toe box and closed heel counter. The proper support of the side of the foot is ensured by the heel counter, the firm fastening of the upper part is ensured by laces, or a velcro with 3 oval rings which ensures good adhesion. The lace-up version called „Rita” is decorated with ventilating perforation, the other version called „Rézi” is made without perforation. The model can be fully opened when putting it on, so the foot can be easily placed into the shoes. The design of the bottom can be combined with a colorful welt, the shoes are made with a mild roller and a led-forward heel.",
    "blake_15_description":  "The perfect sandal shoes with a spongy collar, with double velcro fastening going through the descending strap of the throat. Comfortable street wear with an insole, but their design provides room for putting on an ankle-foot orthosis as well. The chosen sole determines whether we wear them with sporty or elegant clothing.",
    "genf_15_description":  "The Genf model with laces marks a perfect transition between trainers and street shoes. Can be popular among customers with diabetic foot. Its final design is largely determined by the choice of material. The lace-up version with its trendy colors is especially sporty and youthful, while the zip fastening next to the laces makes it possible for young and old that the shoes can be easily put on. Feel free to choose this footwear in case of bunions or hammertoes as well.",
    "madison_15_description":  "Classic, casual shoes with laces or velcro fastening; with traditional, welted, or sporty cupsole. Made with a regular head or a head with decorative stitching, or even with ventilating perforation, if needed. Can be worn with a custom foot orthosis and a custom-made insole as well. Recommended in case of minor foot deformities, for protecting diabetic foot, or in case of bunions and hammertoes as well.",
    "london_15_description":  "Classic, sporty but elegant slip-on with a toe box and a spongy collar. The elastic panels on both sides of the shoes ensure that they conform to the foot perfectly. The design of the bottom is a welt in harmony with the upper part, the sole has a heel height of 15 mm with a mild roller.",
    "denver_15_description": "Simply elegant leather shoes which can be worn at any time and in any situation. Perfect in case of foot deformities or protecting and treating diabetic foot, also recommended in case of bunions and hammertoes. Can be worn with a custom-made insole.",

    "maffia_7_description":  "High-neck ankle shoes which can be easily put on. The design of the upper part can be unique with different color combinations. The upper part of the model is designed with a spongy collar, the velcro with 3 oval rings ensures firm fastening. The bottom can be a sporty cupsole, or a welted or shaped sole with led-forward heel and it!s sewn with a single-color or a colorful thread.",
    "kioto_7_description":  "Special led-forward throat and short vamp ensures that the shoes can be put on easily. With this model, the neck closes with laces or velcro, the bottom is welted sole with a heel height of 7 mm; or a sporty cupsole.",
    "verona_7_description":  "Low-top ankle shoes, the short head and led-forward throat ensures that the shoes can be easily put on. The unique combination of the decorative strap on the side and the heel counter with the pull tab, both makes this model trendy and youthful. The Verona model with sporty laces can be ordered with fur lining for winter. The bottom is a welted sole or a cupsole stiffened with seams.",
    "tallin_7_description":  "Comfortable boots for winter days or even for hiking. Your foot surely won’t get cold with their soft and lined collar part, however for autumn days these shoes can be made with a thinner lining as well. They can be ordered with laces or velcro, with a cupsole or a welted sole. Can be worn with custom-made insoles or foot orthosis as well.",
    "bora_7_description": "With velcro or laces, Bora makes a great transition between trainers and street shoes. Its final design is largely determined by the choice of material. The version with laces and trendy colors is especially sporty and youthful; while the one with velcro fastening can be a good choice for the elderly.",
    "donald_7_description":  "These shoes can be easily put on with their special, led-forward throat and short vamp. The neck of these shoes closes with laces, on the collar part they have hooks commonly used on ice skates. The outside of the model is decorated with a tricolor stripe. The straps which are led inside of the shoes and buckled on the outside, fasten the foot while walking. These shoes can be requested with a second decorative buckle on the neck. The bottom is a welted sole with a led-forward heel on the inside.",
    "tom_for_afo_7_description":  "Sporty shoes with a wide spongy collar wrapping around the foot on the top of the neck. This ensures both the sporty look and the comfort. The upper part can be closed with laces or velcro. The design of the long, led-forward neck ensures that the shoes can be easily put on. The model can be worn with an orthopedic device as well. The shoes are decorated with a strap on the side and with the color combination of the different parts. The bottom is a welted sole sewn with single-color or colorful thread; or a shaped sole with a led-forward heel.",
    "hamburg_7_description":  "This model looks sporty with cool colors and a cupsole, while with a welted sole it looks like regular street shoes. With a toe box and a decorative strap on the throat, this model makes a perfect transition between trainers and street shoes. Its final design is determined by the choice and combination of material.",
    "tiger_for_afo_7_description":  "High-neck sandals with open toe and heel counter. The design of the unique neck top ensures that the shoes can be worn with a orthopedic device in them. The upper part fastens with a well-adhesive velcro with 3 oval rings. The model can be fully opened when putting the shoes on and the foot can be easily placed into the shoes. The design of the bottom is made with a led-forward heel with a mild roller and can be combined with a colorful welt.",
    "frici_7_description":  "High neck sandals with an open toe and closed heel counter, with 3 velcro straps and a buckle on the outside. Next to the velcro straps, the buckle strap ensures stability while walking. The model can be fully opened when putting the shoes on, and the foot can be easily placed into the shoes. The design of the bottom is made with a led-forward heel with a mild roller and can be combined with a colorful welt.",
    "findusz_7_description":  "This model has a special design, it has velcro fastening with oval rings on the back, and widely adjustable laces on the front. Can be fully opened on the heel counter so the shoes can be easily put on with an orthopedic device. There’s a spongy collar on the top of the neck which wraps around the foot; while the sporty, rustic, decorative stitches on the side make this model special. The design of the bottom is an abrasion-resistant sole with a decorative welt.",
    "szaffi_7_description":  "High-top sandals with an open toe and closed heel counter. The proper support of the side of the foot is ensured by a led-forward heel, the upper part is fastened firmly with a well-adhesive velcro with 3 oval rings. The model can be fully opened when putting them on, so the foot can be easily placed into the shoes. The design of the bottom can be combined with a colorful welt, it’s made with a mild roller and a led-forward heel.",
    "tirana_7_description":  "Cross-strap ballerina shoes which can be suitable for special occasions or running errands in summer depending on the choice of material. With its round toe shape, a heel height of 7 mm, and a proper color combination these shoes can be elegant as well. The design on the back of the neck makes this shoe suitable for wearing a custom-made insole too.",
    "barbaro_7_description":  "Open-toe sandals with a closed heel counter. The proper support of the foot is ensured by the led-forward heel counter, the firm fastening of the upper part is ensured by laces, or a well-adhesive velcro tape with 4 oval rings. The model can be fully opened when putting the shoes on, so the foot can be easily placed into the shoes. The design of the bottom is made with a led-forward heel with a mild roller, and can be combined with a colorful welt.",
    "safranek_7_description":  "Open-toe sandals with a heel counter, divided on the side of the neck. The divided feature of the neck makes it possible to design this model with different color combinations. The proper support of the side of the foot is ensured by the led-forward heel counter, the upper part is closed with 2 buckles, and a well-adhesive velcro strap with oval rings on the ankle. The model can be fully opened, or with the proper adjustment of the width of the first buckle, the shoes can be simply, easily put on. The design of the bottom can be combined with a colorful welt, the shoes are made with a led-forward heel and a mild roller. This model is made with a welted, shaped sole.",
    "lucifer_7_description":  "High-neck sandal shoes with closed toe and heel counter. The proper support of the side of the foot is ensured by the led-forward heel counter, the upper part is closed and fastened with a well-adhesive velcro strap with 2 oval rings on the ankle. The model can be fully opened when putting the shoes on, so the foot can be easily placed into the shoes. The design of the bottom is made with a led-forward heel with a mild roller and can be combined with a colorful welt.",
    "senlis_7_description":  "The main feature of this model is the short vamp and led-forward throat, which ensures that you can easily put your shoes on. The cupsole is stiffened with seams, which ensures the sporty look. The side of the neck is decorated with a stitching, while the throat is decorated with a stripe, which ensures many possibilities for combination. The closing feature gives room for tying your shoes in a trendy way.",
    "graz_7_description":  "Graz is fastened with laces; this model makes a perfect transition between trainers and street shoes. The lace-up version with its trendy colors is especially sporty, while the zip fastening next to the laces makes putting your shoes on fast and simple.",
    "pongo_7_description":  "Low-top ankle shoes with led-forward laces, which ensures that you can easily put your shoes on. The design of the neck, the wide spongy collar and the unique color combination makes this model trendy and youthful. This model can be requested with a fur lining for winter. The sporty laces or the velcro straps on the ankle ensure a firm fastening. The design of the sole is a welted shape or a cupsole stiffened with seams.",
    "orion_7_description":  "This model is a high-top shoe which can be easily put on. The design of the upper part makes it possible to decorate the shoes with different colors. The proper support of the side of the foot is ensured by a led-forward heel counter, the upper part is firmly fastened with laces, or with well-adhesive velcro straps with 3 oval rings. The design of the shaped sole is either a sporty cupsole or can be combined with a colorful welt.",
    "helsinki_7_description":  "High-top shoes which can be easily put on the feet. The design of the upper part can be made unique with different color combinations. The upper part of this model is a spongy collar. The velcro straps with 4 oval rings, or the sporty laces ensure a firm fastening. The design of the bottom is a sole sewn with a single-color or multi-color thread, a welted or shaped sole with a led-forward heel.",
    "bedford_7_description":  "Bedford is classic and elegant, has a heel height of 7 mm, it can also be worn as a shoe for special occasions. The perforated cap and the heel counter decoration makes this model special. The sole is a shaped sole with a narrow welt.",
    "bruno_7_description":  "Low-top ankle shoes with led-forward throat, which ensures that you can put your shoes on easily. The spongy collar on the neck and the unique color combination makes this model trendy and youthful. It can be ordered with a fur lining for winter. The sporty laces or the velcro straps ensure a firm fastening. The design of the bottom is a welted sole, or a cupsole stiffened with seams.",
    "cliff_7_description":  "High-neck sandal shoes with closed toe and heel counter. It has a vamp with an up-forward decorative perforation, the velcro straps attach to the vamp. The led-forward heel counter ensures proper support on the side of the foot, the upper part is closed with a firm velcro fastening. The model can be widely opened when putting the shoes on, so the foot can be placed into the shoes easily. The design of the bottom can be combined with a colorful welt, it’s made with a mild roller and a led-forward heel.",
    "rocko_7_description":  "Sandal shoes with a closed vamp, heel counter and velcro fastening. Their up-forward vamp part is made with decorative perforations. The part where the vamp and neck meets, is decorated with a side stripe. Rocko consists of many parts, so the coloring options are endless. The proper support of the side of the foot is ensured by a led-forward heel counter, the upper part is closed with a firm velcro fastening. The design of the bottom can be combined with a colorful welt, it’s made with a mild roller and a led-forward heel counter."
  },
  "product-filters": {
    "categories": "Categories",
    "diabetes": "Diabetes",
    "sports": "Sports",
    "standing": "For people standing at work",
    "everyday": "For everyday life",
    "symptom": "I have foot problems",
    "risc_factor_message": "The specialist required for the final model selection and measurement. Make an appointment!"
  },
  "flow": {
    "model_families": "Model families",
    "customization": "Customization",
    "insole": "Insole",
    "foot_dimensions": "Foot dimensions",
    "finalize_request": "Finalize your request",
    "restart": "Restart",
    "back": "Back",
    "next": "Next",
    "colorer": {
      "info": "Please click on a part to start coloring!",
      "parts": {
        "fej": "vamp",
        "szár":  "high neck",
        "kéregrész és baba/húzófül":  "counter part and puller",
        "kéregrész toldás":  "counter part patching",
        "gallér/gallértoldás":  "collar/collar patching",
        "tépőpántok oválkarika tartóval":  "riptape straps with oval ring holder",
        "alkatrész bélés":  "component lining",
        "főrész bélés":  "uppers lining",
        "CS15": "sole",
        "ráma": "selt",
        "U15": "sole",
        "acéllágyék":"sole support insole steel",
        "baba/húzófül":"puller",
        "Bayer rugó":"spring Bayer",
        "bélés egyéb":"padding other",
        "beragasztó":"sticker",
        "Berlini kéreg":"counter Berlin",
        "bokaszivacs":"ankle sponge",
        "bőrfoglaló":"leather mid-sole",
        "bőrtalp":"leather sole",
        "cérna":"thread",
        "CS20":"sole",
        "CS7":"sole",
        "CS7 járó":"sole",
        "csat":"buckle",
        "csatpánt 2 csatolópánttal":"bucklestrap double strap",
        "csatpántok csattartóval":"bucklestraps with buckleholder",
        "Dioryt":"sole",
        "díszcsík 1":"decor strip 1",
        "díszcsík 2":"decor strip 2",
        "díszcsík 3":"decor strip 3",
        "díszítés figura":"decoration figure",
        "díszpánt oválkarikatartóval":"decor strap with oval ring holder",
        "döntés":"tilting",
        "éksarok":"heel wedge",
        "emelés":"lifting",
        "erősített kéreg":"fixed counter",
        "EVA beragaszó":"sticker EVA",
        "fej és nyelv":"wamp and tongue",
        "fejkarika":"toe cap",
        "fejközép":"vamp",
        "fejközép és nyelv":"wamp and tongue",
        "fejpánt 1":"head strap 1",
        "fejpánt 1 és orrbevonó":"head strap 1 and toecover",
        "fejpánt 2":"head strap 2",
        "fejpánt 2 és toldás":"head strap 2 and patching",
        "foglaló":"mid-sole",
        "foglaló, szabott":"mid-sole cut",
        "fűzörész":"throath",
        "fűzőrész dísz":"throath decoration",
        "gallér":"collar",
        "gallérbélés":"collar lining",
        "gallérszivacs":"collarsponge",
        "gallértoldás":"collar patching",
        "gördülő":"roller",
        "gördülő rátett saroknál":"roller at fixed heel",
        "gumi":"rubber",
        "GY7":"sole",
        "hosszított éksarok":"lengthened heel wedge",
        "járótalp":"outsole",
        "kapli":"toe cap",
        "kaplirész":"toe cap part",
        "kapocs":"hook",
        "kapta építés":"last making",
        "kéreg":"counter  ",
        "kéregrész":"counter part",
        "kierősítő":"heel support",
        "kispánt":"small strap",
        "közbélés":"middle padding",
        "köztalp":"middle sole",
        "MEMORY lépcsős betét":"MEMORY stepped insole",
        "N25":"Sole",
        "nitt":"rivet",
        "nyelv":"tongue",
        "nyelvbélés":"tongue lining",
        "nyelvdísz":"tongue decoration",
        "nyelvszivacs":"tongue sponge",
        "orrbevonó":"toe cover",
        "orrboríték":"toe cap",
        "oválkarika":"oval ring",
        "patent":"press-button",
        "ragasztó":"sticker",
        "ringli":"eyelet",
        "sarok":"heel",
        "sarokbevonó":"heel covering",
        "sarokjáró":"top piece",
        "szár belső":"upper inner",
        "szár külső":"upper external",
        "szárdísz":"upper decoration",
        "szártoldás":"upper patching",
        "szendvics betét":"sandwitch insole",
        "talp merevítő":"sole stiffener",
        "tépő pántvég":"riptape strap end",
        "tépőpántok oválkarika tartóval és pántvéggel":"riptape straps with oval ring holder and strapend",
        "tépőzár bolyhos":"riptape fluffy",
        "tépőzár horgos":"riptape hooked",
        "textilszalag":"textile ribbon",
        "U7":"sole",
        "vastag puha betét":"thick soft insole",
        "zippzár":"zip-fastener",
        "zippzártakaró":"zip covering",
        "zipzártakaró":"zip covering"
      },
      "colors": {

      }
    },
    "insoles": {
      "DR_ORTO_COMFORT_PU_B1": "For those wanting to protect their feet, having an early-stage diabetes, or want to replace the original insole of their regular comfort shoes. it’s Comfortable and soft insole with an antibacterial, moisture-repellent, alcantara-like coating.",
      "DR_ORTO_DIAB_MEMORY_PLUS_PU_B2": "Soft insole for diabetic feet, with an elevation for the longitudinal and transverse arch which can be adapted with grinding. Elegant, soft insole with antibacterial features.",
      "DR_ORTO_DIAB_MEMORY_EXTRA_B3": "Special insole for treating ulcer, suitable for the weight distribution of metatarsal heads. Recommended for diabetic patients already having a wound on their foot.",
      "choose_this": "I choose this",
      "insole_selection": "Insole selection"
    },
    "sizes": {
      "main_text": "To make sure your footwear really fits your foot, we need you to give all your measurement details. In case you struggle with a more complex foot problem, and you would like to ask for the opinion of our experts for measuring the sizes of your feet because you feel this would be safer, continue the ordering process without giving your measurements. At the end of the order process, you will have the opportunity to book an appointment in one of our shops where measuring is possible.",
      "image_upload_text": "You also can attach photos of your feet to your order. If you wish to use this option, please choose the photos you’d like to upload by clicking “Browse”.",
      "browse": "Browse",
      "sizing": "Foot dimensions",
      "foot_length_left": "Foot length left",
      "foot_length_right": "Foot length right",
      "foot_length_text": "Place your heel to a vertical surface, for example to a wall. Measure the distance between the wall and your longest toe. You can mark this most precisely if you place a cardboard box to your longest toe.",
      "bunion_width_left": "Ball girth left",
      "bunion_width_right": "Ball girth right",
      "bunion_width_text": "Place the tape measure on the outer, inner and upper part of the bunion and give the circumferance in mm.",
      "bridge_width_left": "Instep girth left",
      "bridge_width_right": "Instep girth right",
      "bridge_width_text": "Place the tape measure on bridge point and the highest point of the inner arch and give circumferance in mm.",
      "heel_width_left": "Heel girth left",
      "heel_width_right": "Heel girth right",
      "heel_width_text": "Place the tape measure on the bending point of the foot and the measuring point of the heel width, and give the circumferance in mm."
    },
    "order": {
      "your_request": "Your request",
      "selected_model": "Selected model",
      "materials": "Materials",
      "measurements_left": "Measurements on left foot",
      "measurements_right": "Measurements on right foot",
      "insole": "Insole",
      "illness": "Illness",
      "bottom_text": "The parts which are not described here are made with materials in a matching color  to the model.",
      "sizes": {
        "leftLength": "Foot length left",
        "rightLength": "Foot length right",
        "leftBunnionWidth": "Bunion width left",
        "rightBunnionWidth": "Bunion width right",
        "leftBridgeSize": "Bridge width left",
        "rightBridgeSize": "Bridge width right",
        "leftHeelCircumference": "Heel width left",
        "rightHeelCircumference": "Heel width right"
      },
      "finalize_request": "Finalize your request"
    },
    "contact-form": {
      "confirmation_of_request": "Confirmation of request",
      "request_details": "Request details",
      "required_fields": "Fields marked with an asterisk are required",
      "top_text": "Please enter your name, email address and phone number. Please provide contact information that is available during business hours.",
      "gtc": "I accept the <a href='/general-business-terms.pdf' target='_blank'>Terms and Conditions</a>",
      "email_info": "Would you like to receive information by e-mail about changes and current events affecting health care?",
      "subscribe": "Subscribe",
      "privacy_policy": "I accept the Privacy Policy.",
      "submit_your_request": "Submit your request",
      "name": "Name",
      "email": "E-mail address",
      "phone": "Phone number",
      "comment": "Comment"
    }
  },
  "file_upload": {
    "back": "Cancel",
    "upload": "Upload %s% pictures",
    "max_count": "*Max file size: 1 Mb, Max image count: %s%"
  }
}