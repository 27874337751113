<template>
    <p>Showing {{ total }} products, loading {{ count }} at once</p>
</template>

<script>
    export default {
        props: {
            count: Number,
            total: Number
        }
    }
</script>

<style>
</style>