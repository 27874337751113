<template>
    <div class="catalogue-error">Catalogue error: {{ error }}</div>
</template>

<script>
    export default {
        props: {
            error: String
        }
    }
</script>

<style>
</style>