<template>
  <div class="card">
    <div :class="{'card-image': true, 'is-hidden': !image}">
      <slot name="card-image"></slot>
    </div>
    <div class="card-content">
      <slot name="card-content"></slot>
    </div>
  </div>
</template>

<script>
import "./card.scss";
export default {
  name: "CardItem",
  props: {
    image: Boolean
  }
}
</script>

<style scoped>

</style>