<template>
    <div class="app-with-authentication">
        <div class="authentication-error" v-if="!!error">
            <p>Hibába futottunk inicializáció közben. Nagyon sajnáljuk, próbálja meg frissíteni az oldalt!</p>
            <p class="error-message">{{ error }}</p>
        </div>
        <Loading v-else-if="!isAuthenticated" />
        <Main v-else />
    </div>
</template>

<script>
import { GraphQLClient, gql } from "@diabfoot/graphql-client";
import Loading from "@diabfoot/component-loading";
import Main from "@diabfoot/component-main";
import { idServiceUrl } from "@diabfoot/app-config";

export default {
    components: {Loading, Main},
    async mounted() {

        let currentToken = localStorage.getItem("token");
        if(currentToken) {
            const splitted = currentToken.split(".");
            const payload = JSON.parse(atob(splitted[1]));
            const exp = payload.exp;
            if(exp < Date.now() / 1000) {
                localStorage.removeItem("token");
            } else {
                this.isAuthenticated = true;
                return;
            }
        }

        const client = new GraphQLClient({
            baseUrl: idServiceUrl()
        });

        const apolloClient = client.apolloClient;

        try {

            const {data, errors} = await apolloClient.mutate({
                mutation: gql`
                    mutation {
                        id {
                            loginAnonymous {
                                token
                                user {
                                    id
                                    name
                                    type
                                }
                            }
                        }
                    }
                `
            });

            if(Array.isArray(errors) && errors.length > 0) {
                this.error = errors.map(err => err.message).join(", ");
            } else {
                this.error = false;
                localStorage.setItem("token", data.id.loginAnonymous.token);
                localStorage.setItem("user", JSON.stringify(data.id.loginAnonymous.user));
                this.isAuthenticated = true;
            }

        } catch(err) {
            this.error = err;
        }

    },
    data() {
        return {
            isAuthenticated: false,
            error: null
        }
    }
}
</script>