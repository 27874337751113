import getOpt from "@diabfoot/get-opt";

export class BasketClient {
    constructor(opts) {
        this.httpClient = getOpt(opts, "httpClient");

        this._items = [];
    }

    async loadItemById(id) {
        const itemValue = localStorage.getItem(`customized-items.${id}`);
        if (itemValue !== null && itemValue.length > 0) {
            return JSON.parse(itemValue);
        }
        throw new Error(`No such customized item: ${id}`);
    }

    async loadItems() {

        const itemIds = localStorage.getItem("basket.item-ids");
        if (itemIds !== null) {
            const itemIdsArray = JSON.parse(itemIds);
            const loadedItems = [];
            for (let id of itemIdsArray) {
                const item = await this.loadItemById(id);
                loadedItems.push(item);
            }
            this._items = loadedItems;
        } else {
            this._items = [];
        }

        return this._items;

    }

    async addItem(id) {

        const itemIds = [...this._items.map(item => item.id)];
        itemIds.push(id);
        this._items.push(await this.loadItemById(id));
        localStorage.setItem("basket.item-ids", JSON.stringify(itemIds));

    }

    async removeItem(id) {

        this._items = this._items.filter(item => item.id !== id);
        const itemIds = [...this._items.map(item => item.id)];
        localStorage.setItem("basket.item-ids", JSON.stringify(itemIds));

    }

    async clearBasket() {
        this._items = [];
        localStorage.removeItem("basket.item-ids");
    }
}