<template>
    <div class="customization-color-chooser box">
        <p v-if="empty"></p>
<!--        <tabs-view v-else @tab-selected="onGroupTabChanged">
            <tab v-for="group of groups" :key="group.group" :tab-id="group.group" :title="group.group">
                <div>
                    <color-swatch v-for="color of colors" :key="color.id" :color-id="color.id" :rgb="color.rgb" :image-url="color.imageUrl" :name="color.name" :is-selected="color.id === selectedColor" @selected="onColorSelected"></color-swatch>
                </div>
            </tab>
        </tabs-view>-->
        <div v-else>
            <a class="icon has-text-info is-relative">
                <i class="info-icon"></i>
                <tooltip>
                    <ul>
                        <li>A boxbőr vékony fedőréteggel ellátott,  sima felületű felsőbőr.</li>
                        <li>A barkás boxbőr felülete különböző formájú nyomott mintázattal díszített, sportos jellegű felsőbőr.</li>
                        <li></li>
                        <li>A nubuk bőr csiszolt, enyhén bolyhos, bársonyos felületű felsőbőr.</li>
                        <li>A barkás nubuk bőr felülete különböző mintázatú filmréteggel ellátott, vagy erőteljes présnyomással díszített felsőbőr</li>
                    </ul>
                </tooltip>
            </a>
            <div v-for="group of groups" :key="group.group">
                <h2 class="title">
                    {{group.group}}
                </h2>
                <color-swatch v-for="color of group.colors" :key="color.id" :color-id="color.id" :rgb="color.rgb" :image-url="color.imageUrl" :name="color.name" :is-selected="color.id === selectedColor" @selected="onColorSelected"></color-swatch>
            </div>
        </div>
    </div>
</template>

<script>
import TabsView from "@diabfoot/component-tabs-view";
import Tab from "@diabfoot/component-tab";
import ColorSwatch from "@diabfoot/component-color-swatch";
import Tooltip from "@diabfoot/component-tooltip";
import "./customization-color-chooser.scss";
export default {
    components: {
        Tab,
        TabsView,
        ColorSwatch,
        Tooltip
    },
    data() {
        return {
            groups: [],
            colors: [],
            selectedGroup: null,
            selectedColor: null,
            empty: true
        };
    },
    props: {
        productId: String,
        customizations: Array,
        selectedCustomizationId: String
    },
    async mounted() {
        const customization = this.customizations.find(c => c.id === this.selectedCustomizationId);
        if(customization) {
            this.groups = customization.color.groups;
            this.empty = false;
            await this.selectGroup(this.groups[0].id);
        } else {
            this.groups = [];
            this.colors = [];
            this.selectedGroup = null;
            this.selectedColor = null;
            this.empty = true;
        }
    },
    methods: {
        async selectGroup(id) {
            this.selectedGroup = id;
            const group = this.groups.find(g => g.group === id);
            if(!!group) {
                this.colors = group.colors;
                this.selectedColor = group.colors[0].id;
            } else {
                this.colors = [];
                this.selectedColor = null;
            }
        },
        async onGroupTabChanged(newTabId) {
            await this.selectGroup(newTabId);
        },
        async onColorSelected(colorId) {
            this.selectedColor = colorId;
            this.$emit("selected-color-changed", colorId);
        }
    }
}
</script>