<template>
    <scaffold fluid class="landing-page">
        <template #content>
            <loading v-if="loading"></loading>
            <div v-else>
                <flow-progress :steps="flowSteps" :current-step="currentFlowStep" v-if="stage !== 'CONTACT_FORM'"></flow-progress>

                <product-chooser v-if="stage === 'CHOOSE_PRODUCT'" :product-family-id="productFamilyId"
                                 :illness-answers="illnessAnswers" @choose-product="onChooseProduct"></product-chooser>
                <color-customization v-else-if="stage === 'COLOR_CUSTOMIZATION'" :product-family-id="productFamilyId"
                                     :product-id="productId" :illness-answers="illnessAnswers" @back="onBack"
                                     @finish-customization="onFinishColorCustomization" @reset="onReset"></color-customization>
                <size-customization v-else-if="stage === 'SIZE_CUSTOMIZATION'" :product-family-id="productFamilyId"
                                    :product-id="productId" :illness-answers="illnessAnswers" @back="onBack" @reset="onReset"
                                    @finish-customization="onFinishSizeCustomization"></size-customization>
                <options-customization v-else-if="stage === 'OPTIONS_CUSTOMIZATION'"
                                       :product-family-id="productFamilyId" :product-id="productId"
                                       :illness-answers="illnessAnswers" @back="onBack" @reset="onReset"
                                       @finish-customization="onFinishOptionsCustomization"></options-customization>
                <order v-else-if="stage === 'ORDER'" :product-family-id="productFamilyId" :product-id="productId"
                       :product-name="productName"
                       :applied-colors="appliedColors" :applied-sizes="appliedSizes" :applied-options="appliedOptions"
                       :illness-answers="illnessAnswers" @back="onBack" @finish="onFinishOrder" @reset="onReset"></order>
                <contact-form v-else :product-id="productId"
                              :applied-colors="appliedColors" :applied-sizes="appliedSizes"
                              :applied-options="appliedOptions"
                              :illness-answers="illnessAnswers"></contact-form>
            </div>
        </template>
    </scaffold>
</template>

<script>
import Scaffold from "@diabfoot/component-scaffold";
import ProductChooser from "@diabfoot/component-product-chooser";
import ColorCustomization from "@diabfoot/component-color-customization";
import SizeCustomization from "@diabfoot/component-size-customization";
import OptionsCustomization from "@diabfoot/component-options-customization";
import ContactForm from "@diabfoot/component-contact-form/ContactForm";
import Order from "@diabfoot/component-order";
import FlowProgress from "@diabfoot/component-flow-progress";
import {catalogueClient} from "@diabfoot/client-cache";
import Loading from "@diabfoot/component-loading";

export default {
    components: {
        Scaffold,
        ProductChooser,
        ColorCustomization,
        SizeCustomization,
        OptionsCustomization,
        Order,
        ContactForm,
        FlowProgress,
        Loading
    },
    data() {
        return {
            productFamilyId: null,
            productFamilyName: null,
            stage: "CHOOSE_PRODUCT",
            productId: null,
            appliedColors: [],
            appliedSizes: [],
            appliedOptions: [],
            illnessAnswers: [],
            loading: true,
            flowSteps: [],
            productName: null,
            productCatalogId: null
        };
    },
    async mounted() {

        this.productFamilyId = this.route.groups[1];
        this.productCatalogId = this.route.groups[2];

        const productFamily = await catalogueClient.getProductFamily(
            this.productFamilyId,
            this.productCatalogId
        );
        this.flowSteps = [
            this.$t('model_families'),
            productFamily.name,
            this.$t('customization'),
            this.$t('insole'),
            this.$t('foot_dimensions'),
            this.$t('finalize_request')
        ];

        this.illnessAnswers = JSON.parse(
            localStorage.getItem("illnesses")
        );

        this.loading = false;

    },
    inject: ["route"],
    methods: {
        async onChooseProduct(product) {
            this.productId = product.id;
            this.productName = product.name;
            this.stage = "COLOR_CUSTOMIZATION";
            window.scrollTo(0, 0);
        },
        async onFinishColorCustomization(appliedCustomizations) {
            this.stage = "OPTIONS_CUSTOMIZATION";
            this.appliedColors = appliedCustomizations;
            window.scrollTo(0, 0);
        },
        async onFinishSizeCustomization(appliedCustomizations) {
            this.stage = "ORDER";
            this.appliedSizes = appliedCustomizations;
            window.scrollTo(0, 0);
        },
        async onFinishOptionsCustomization(appliedCustomizations) {
            this.stage = "SIZE_CUSTOMIZATION";
            this.appliedOptions = appliedCustomizations;
            window.scrollTo(0, 0);
        },
        async onFinishOrder() {
            this.stage = "CONTACT_FORM";
            window.scrollTo(0, 0);
        },
        async onBack() {
            if(this.stage === "CHOOSE_PRODUCT") {
                window.location.hash = `#catalogue/${this.productCatalogId}`;
            } else if (this.stage === "COLOR_CUSTOMIZATION") {
                this.stage = "CHOOSE_PRODUCT";
            } else if (this.stage === "SIZE_CUSTOMIZATION") {
                this.stage = "OPTIONS_CUSTOMIZATION";
            } else if (this.stage === "OPTIONS_CUSTOMIZATION") {
                this.stage = "COLOR_CUSTOMIZATION";
            } else if (this.stage === "ORDER") {
                this.stage = "SIZE_CUSTOMIZATION";
            } else if(this.stage === "CONTACT_FORM") {
                this.stage = "ORDER";
            }
        },
        onReset() {
            this.stage = "CHOOSE_PRODUCT";
            this.productId = null;
            this.appliedColors = [];
            this.appliedSizes = [];
            this.appliedOptions = [];
            window.location.hash = `#/catalogue/${this.productCatalogId}`;
        },
        $t(key){
            return this.$i18n.t(`flow.${key}`);
        }
    },
    computed: {
        currentFlowStep() {
            if(this.stage === "CHOOSE_PRODUCT") {
                return 1;
            } else if(this.stage === "COLOR_CUSTOMIZATION") {
                return 2;
            } else if(this.stage === "OPTIONS_CUSTOMIZATION") {
                return 3;
            } else if(this.stage === "SIZE_CUSTOMIZATION") {
                return 4;
            } else if(this.stage === "ORDER") {
                return 5;
            } else if(this.stage === "CONTACT_FORM") {
                return 6;
            }
            return 0;
        }
    }
};
</script>
