import hu from "./dictionaries/dictionary.hu.json";
import en from "./dictionaries/dictionary.en.json";

export default class I18NClient {

    static LANG_EN = "en";
    static LANG_HU = "hu";

    static DEFAULT_LANG = I18NClient.LANG_EN;
    static DEFAULT_LANGUAGES = [
        I18NClient.LANG_EN,
        I18NClient.LANG_HU
    ]

    constructor() {
        if(!localStorage.getItem('lang')) {
            if(I18NClient.DEFAULT_LANGUAGES.includes(window.navigator.language.split('-')[0])) {
                this.selectedLang = window.navigator.language.split('-')[0];
            } else {
                this.selectedLang = I18NClient.DEFAULT_LANG;
            }
        }
        if(I18NClient.DEFAULT_LANGUAGES.includes(localStorage.getItem('lang'))) {
            this.setSelectedLang(localStorage.getItem('lang'))
        }
        this.dictionary = {
            hu,
            en
        };
    }

    t(key, parameter = null) {
        return this.parametricValues(this.getValue(this.dictionary[this.selectedLang], key), parameter) ?? key;
    }


    getValue(obj, path) {
        if (!path) return obj;
        const properties = path.split('.');
        return this.getValue(obj[properties.shift()], properties.join('.'))
    }

    setSelectedLang(lang) {
        this.selectedLang = lang;
    }

    //TODO: Only works with 1 parameter, should fix it!
    parametricValues(value, parameter) {
        if(parameter === null) {
            return value;
        }
        return value.replace(/%s%/, parameter)
    }

}