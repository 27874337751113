<template>
    <div class="contact-form">
        <loading v-if="loading"></loading>
        <div class="header-line">
            <h3>{{ $t('confirmation_of_request') }}</h3>
            <div class="orange-line"></div>
        </div>
        <div class="form columns">
            <div class="column is-three-fifths is-offset-one-fifth">
                <form @submit="submit">
                    <h2>{{ $t('request_details') }}</h2>
                    <label>{{ $t('required_fields') }}</label>
                    <h3>{{ $t('top_text') }}</h3>
                    <div class="columns">
                        <div class="column">
                            <input type="text" v-model="name" :placeholder="$t('name')" required/>
                        </div>
                        <div class="column"></div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <input type="text" v-model="email" :placeholder="$t('email')" required/>
                        </div>
                        <div class="column">
                            <input type="text" v-model="phoneNumber" :placeholder="$t('phone')" required/>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <textarea :placeholder="$t('comment')" v-model="comment"></textarea>
                        </div>
                    </div>
                    <p>{{ $t('email_info') }}</p>
                    <input type="checkbox" id="subscribe"><label class="checkbox-label" for="subscribe">{{ $t('subscribe') }}</label><br/>
                    <input type="checkbox" required id="privacy-policy"><label class="checkbox-label" for="privacy-policy">{{ $t('privacy_policy') }}</label><br/>
                    <input type="checkbox" required id="gtc"><label class="checkbox-label" for="gtc" v-html="$t('gtc')"></label><br/>
                    <input type="submit" :value="$t('submit_your_request')">
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {orderClient} from "@diabfoot/client-cache/index.mjs";
import Scaffold from "@diabfoot/component-scaffold/Scaffold";
import Loading from "@diabfoot/component-loading/Loading";
import "./contact-form.scss";

export default {
    name: "ContactForm",
    components: {
        Scaffold,
        Loading
    },
    props: {
        productId: String,
        appliedColors: Array,
        appliedSizes: Array,
        appliedOptions: Array,
        illnessAnswers: Array,
    },
    data() {
        return {
            name: null,
            phoneNumber: null,
            email: null,
            comment: null,
            loading: false
        }
    },
    methods: {
        async submit(e) {
            e.preventDefault();
            this.loading = true;

            const appliedColors = this.appliedColors.map(ac => ({customizationId: ac.id, colorId: ac.color}));

            await orderClient.saveOrder({
                productId: this.productId,
                appliedCustomizations: appliedColors.concat(this.appliedSizes, this.appliedOptions),
                illnessAnswers: this.illnessAnswers ?? [],
                customer: {
                    email: this.email,
                    name: this.name,
                    phoneNumber: this.phoneNumber,
                    comment: this.comment
                }
            });

            this.loading = false;
            window.location.hash = "#landing-page/1";
        },
        $t(key) {
            return this.$i18n.t(`flow.contact-form.${key}`);
        }
    }
}
</script>

<style scoped>

</style>