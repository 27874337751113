import { getCookie } from "@diabfoot/get-cookie";

export function backendUrl() {
    if (!window.DIABFOOT_BACKEND_URL || window.DIABFOOT_BACKEND_URL.length === 0) {
        const gwurlCookie = getCookie('gwurl');
        if (gwurlCookie) {
            window.DIABFOOT_BACKEND_URL = gwurlCookie;
        }
    }
    return typeof window.DIABFOOT_BACKEND_URL === "string"
        ? window.DIABFOOT_BACKEND_URL
        : "http://localhost:8080/graphql-backend-gateway";
}

export function idServiceUrl() {
    if (!window.DIABFOOT_ID_SERVICE_URL || window.DIABFOOT_ID_SERVICE_URL.length === 0) {
        const idurlCookie = getCookie('idurl');
        if (idurlCookie) {
            window.DIABFOOT_ID_SERVICE_URL = idurlCookie;
        }
    }
    return typeof window.DIABFOOT_ID_SERVICE_URL === "string"
        ? window.DIABFOOT_ID_SERVICE_URL
        : "http://localhost:8080/id-service-gateway";
}
