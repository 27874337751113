{
  "illness": "Az ön betegsége:",
  "test": "Teszt",
  "cukorbeteg": "Cukorbeteg vagyok",
  "morethan7years": "Több, mint 7 éve vagyok cukorbeteg",
  "erzeszavar": "Érzészavart tapasztalok a lábamban",
  "fekelycukorbeteg": "Lábam fekélyes a cukorbetegség következményeként",
  "kronikusbetegseg": "Krónikus betegség következtében lábdeformitásom van",
  "erszukulet": "Érszűkületem van",
  "benulasos": "Bénulásos betegségben szenvedek",
  "izuletizsugorodas": "Ízületi zsugorodás van a lábamon",
  "limfodema": "Limfödémám van",
  "reumasbetegseg": "Reumás megbetegedésem van",
  "seb": "Seb van a lábamon",
  "benulasos_labbetegseg": "Bénulásos lábbetegsége van?",
  "butyok": "Önnek  van-e bütyke?",
  "kalapacsujj": "Önnek van-e kalapácsujja vagy karom állású ujja?",
  "labdeformitas": "Lábam eltér az átlagos lábformától",
  "veleszuletett_dongalab": "Veleszületett dongalába van?",
  "tyukszem": "Tyúkszem van a lábán?",
  "benottkorom": "Van benőtt körme?",
  "borbetegseg": "Lábat érintő bőrbetegségem van",
  "borpotlas": "Korábbi sérülés miatt bőrpótlás van a lábamon",
  "gombasodas": "Gombásodás miatt megvastagodott a körmöm",
  "ekcema": "Ekcémás vagyok",
  "specialisellatas": "Speciális ellátásra van szükségem",
  "hagyomanyosortezis": "Hagyományos boka/láb ortézist viselek",
  "dinamikusortezis": "Dinamikus boka-láb ortézist viselek (Toe Off)",
  "vegtaghosszkulonbseg": "Végtaghossz különbségem van",
  "csonkoltlab": "Csonkolt a lábam",
  "images": {
    "karomallas_ujj": "Karomállás ujj",
    "tyukszem": "Lábujjak közötti tyúkszem",
    "kisbutyok": "Kis bütyök",
    "labujjon_feluli_tyukszem": "Lábujjon (felül) lévő tyúkszem",
    "belso_oldali_butyok": "Belső oldali bütyök",
    "talp_tyukszem": "Talpon lévő tyúkszem",
    "kulso_oldali_butyok": "Külső oldali bütyök",
    "benott_korom": "Benőtt köröm",
    "ketoldali_butyok": "Kétoldali bütyök",
    "dongalab": "Dongaláb",
    "kalapacsujj": "Kalapácsujj"
  }
}