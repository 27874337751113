<template>
    <li :class="{'is-active': active}" @click="selectTab">
        <a>{{ title }}</a>
    </li>
</template>

<script>
export default {
    data() {
        return {
            component: null
        }
    },
    props: {
        tabId: String,
        title: String,
    },
    inject: ["tabsView"],
    async mounted() {
        await this.tabsView.registerTab(this);
    },
    computed: {
        active() {
            return this.tabsView.selectedTabId === this.tabId;
        },
        component() {
            return null;
        }
    },
    methods: {
        async selectTab() {
            await this.tabsView.selectTab(this.tabId);
        }
    }
}
</script>