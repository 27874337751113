import { backendUrl } from "@diabfoot/app-config";
import { HttpClient } from "@diabfoot/http-client";
import { CatalogueClient } from "@diabfoot/catalogue-client";
import { CustomizerClient } from "@diabfoot/customizer-client";
import { BasketClient } from "@diabfoot/basket-client";
import { OrderClient } from "@diabfoot/order-client";
import { GraphQLClient } from "@diabfoot/graphql-client";
import I18NClient from "@diabfoot/i18n-client";
import { ProductFilterClient } from "@diabfoot/product-filter-client";
import CatalogueFilterClient from "@diabfoot/catalogue-filter-client";

export const httpClient = new HttpClient({ baseUrl: backendUrl() });
export const graphqlClient = new GraphQLClient({ baseUrl: backendUrl() });
export const catalogueClient = new CatalogueClient({ graphqlClient });
export const customizerClient = new CustomizerClient({ graphqlClient });
export const basketClient = new BasketClient({ httpClient });
export const orderClient = new OrderClient({ graphqlClient });
export const i18nClient = new I18NClient();
export const catalogueFilterClient = new CatalogueFilterClient();
export const productFilterClient = new ProductFilterClient({ graphqlClient });