<template>
    <div class="modal" ref="modal">
        <div class="modal-background"></div>
        <div class="modal-content">
            <button class="modal-close is-large" v-if="closeable" aria-label="close"></button>
            <slot></slot>
        </div>
    </div>
</template>

<script>
import "./modal.scss";
export default {
    props: {
      closeable: {
        type: Boolean,
        default: true
      },
    },
    mounted() {
        if(this.closeable) {
            this.$nextTick(() => {
                (this.$refs.modal.querySelectorAll('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button') || []).forEach(($close) => {
                    $close.addEventListener('click', () => {
                        this.closeModal();
                    });
                });
            });
        }
    },
    methods: {
        openModal() {
            this.$refs.modal.classList.add("is-active");
        },
        closeModal() {
            this.$refs.modal.classList.remove("is-active");
        }
    }
}
</script>