<template>
    <loading v-if="loading"></loading>
    <component :key="routeRevision" :is="routeComponent" v-else @navigate="onNavigation"></component>
</template>

<script>
    import Loading from '@diabfoot/component-loading'
    import UnknownRoute from "@diabfoot/component-unknown-route"
    import routes from "./routes"
    import {computed} from "vue";

    function findRoute() {
        let path = window.location.hash;
        if (path.startsWith("#")) {
            path = path.substring(1);
        }
        if (path.startsWith("/")) {
            path = path.substring(1)
        }
        for (let r of routes) {
            console.log(r, "vs", path);
            if (typeof r.path === "string") {
                if (r.path === path) {
                    return r;
                }
            } else if (typeof r.path.test === "function") {
                if (r.path.test(path)) {
                    return {...r, groups: r.path.exec(path)};
                }
            }
        }
        return null;
    }

    export default {
        components: { Loading },
        data() {
            return {
                loading: true,
                route: null,
                routeComponent: UnknownRoute,
                routeRevision: 1
            }
        },
        created() {
            window.onhashchange = () => {
                this.findRouteComponent();
            };
        },
        async mounted() {
            this.findRouteComponent();
            this.loading = false;
        },
        methods: {
            async onNavigation(to) {
                if (typeof to === "string") {
                    window.location.hash = to;
                }
                window.scrollTo(0, 0);
            },
            async onHashChange() {
                this.findRouteComponent();
            },
            findRouteComponent() {
                this.route = findRoute();
                if (this.route !== null && typeof this.route === "object") {
                    this.routeComponent = this.route.component;
                } else {
                    this.routeComponent = UnknownRoute;
                }
                this.routeRevision = this.routeRevision + 1;
            }
        },
        provide() {
            return {
                route: computed(() => this.route)
            }
        }
    }
</script>

<style>
</style>