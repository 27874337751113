<template>
    <div class="color-customization">
        <p v-if="error">Hiba történt: {{ error }}</p>
        <loading v-if="loading"></loading>
        <div class="container is-fluid pb-6" v-else>
            <div class="header-line">
                <h3>{{ productName }}</h3>
                <div class="orange-line"></div>
                <button @click="resetStep">{{ $i18n.t('flow.restart') }}</button>
            </div>
            <div class="columns is-desktop">
                <div class="column">
                    <div class="notification is-info is-light">
                        <span class="icon has-text-info">
                          <i class="info-icon"></i>
                        </span>
                        {{ $i18n.t('flow.colorer.info') }}
                    </div>
                    <colored-part-chooser
                        :product-id="productId"
                        :customizations="customizations"
                        :applied-customizations="appliedCustomizations"
                        :selected-customization-id="selectedCustomizationId"
                        @selected-part-changed="onSelectedPartChanged"
                        :key="revision"
                    ></colored-part-chooser>
                </div>
                <div class="column">
                    <customization-color-chooser
                        :product-id="productId"
                        :customizations="customizations"
                        :applied-customizations="appliedCustomizations"
                        :selected-customization-id="selectedCustomizationId"
                        @selected-color-changed="onSelectedColorChanged"
                        :key="selectedCustomizationId"
                    ></customization-color-chooser>
                </div>
            </div>

            <div class="is-flex is-justify-content-center is-align-items-center button-container">
                <button class="button back-button" @click="onBack">{{ $i18n.t('flow.back') }}</button>
                <button class="button next-button" @click="onFinish">{{ $i18n.t('flow.next') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import ColoredPartChooser from "@diabfoot/component-colored-part-chooser";
import CustomizationColorChooser from "@diabfoot/component-customization-color-chooser";
import { customizerClient, catalogueClient } from "@diabfoot/client-cache";
import Loading from "@diabfoot/component-loading/Loading";
import "./color-customization.scss";

export default {
    components: {
        ColoredPartChooser,
        CustomizationColorChooser,
        Loading
    },
    props: {
        productId: String
    },
    async mounted() {
        const customizations = await customizerClient.listCustomizations({
            productId: this.productId,
        });
        this.customizations = [...customizations].filter(
            (c) => c.type === "COLOR"
        );

        const product = await catalogueClient.getProduct(this.productId);
        this.productName = product.name;

        this.loading = false;
    },
    data() {
        return {
            productName: null,
            loading: true,
            error: null,
            customizations: [],
            appliedCustomizations: [],
            selectedCustomizationId: null,
            revision: 1,
        };
    },
    inject: ["route"],
    methods: {
        async onSelectedPartChanged(newCustomizationId) {
            this.selectedCustomizationId = newCustomizationId;
        },
        async onSelectedColorChanged(newColorId) {
            const existingAppliedCustomization = this.appliedCustomizations.find(
                (c) => c.id === this.selectedCustomizationId
            );
            if (existingAppliedCustomization) {
                const newAppliedCustomizations = this.appliedCustomizations.filter(
                    (c) => c.id !== this.selectedCustomizationId
                );
                existingAppliedCustomization.color = newColorId;
                newAppliedCustomizations.push(existingAppliedCustomization);
                this.appliedCustomizations = newAppliedCustomizations;
            } else {
                this.appliedCustomizations = [
                    ...this.appliedCustomizations,
                    {
                        id: this.selectedCustomizationId,
                        color: newColorId,
                    },
                ];
            }

            this.revision = this.revision + 1;
        },
        async onBack() {
            this.$emit("back");
        },
        async onFinish() {
            this.$emit("finish-customization", this.appliedCustomizations);
        },
        resetStep() {
            this.$emit('reset');
        }
    },
};
</script>
