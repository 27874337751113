{
  "illness": "Az ön betegsége:",
  "test": "Teszt",
  "cukorbeteg": "I am diabetic",
  "morethan7years": "I have been diabetic for over 7 years",
  "erzeszavar": "I experience a sensory disturbance in my leg",
  "fekelycukorbeteg": "My feet are ulcerated as a result of diabetes",
  "kronikusbetegseg": "I have leg deformity due to a chronic illness",
  "erszukulet": "I have vasoconstriction",
  "benulasos": "I have a paralytic illness",
  "izuletizsugorodas": "I have joint shrinkage in my leg",
  "limfodema": "I have lymphedema",
  "reumasbetegseg": "I have a rheumatic disease",
  "seb": "I have a wound on my leg",
  "benulasos_labbetegseg": "Do you have paralytic foot disease?",
  "butyok": "Do you have a bunion?",
  "kalapacsujj": "Do you have a hammer toe or claw toe?",
  "labdeformitas": "My feet are different from the average foot shape",
  "veleszuletett_dongalab": "Do you have a congenital clubfoot?",
  "tyukszem": "Do you have callus?",
  "benottkorom": "Do you have ingrown toenails?",
  "borbetegseg": "I have a skin condition that affects my feet",
  "borpotlas": "I have a skin replacement on my leg due to a previous injury",
  "gombasodas": "My toenails thickened due to fungus",
  "ekcema": "I'm eczematous",
  "specialisellatas": "I need special treatment",
  "hagyomanyosortezis": "I wear a traditional ankle / foot orthosis",
  "dinamikusortezis": "I wear a dynamic ankle-foot orthosis (Toe Off)",
  "vegtaghosszkulonbseg": "I have a difference in limb length",
  "csonkoltlab": "I have a truncated leg",
  "images": {
    "karomallas_ujj": "Claw toe",
    "tyukszem": "Callus between the toes",
    "kisbutyok": "Small bunion",
    "labujjon_feluli_tyukszem": "Callus above the toe",
    "belso_oldali_butyok": "Inside bunion",
    "talp_tyukszem": "Callus on the sole",
    "kulso_oldali_butyok": "Outside bunion",
    "benott_korom": "Ingrown toenails",
    "ketoldali_butyok": "Double-sided bunion",
    "dongalab": "Clubfoot",
    "kalapacsujj": "Hammer toe"
  }
}