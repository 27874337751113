import { XMLParser, XMLBuilder } from "fast-xml-parser";
import { logWarn } from "@diabfoot/logger";

function findAllElementsRecursive(
    parent,
    tagToFind
) {
    if (typeof parent[tagToFind] === "undefined") {
        return [];
    }
    if (Array.isArray(parent[tagToFind])) {
        const result = [...parent[tagToFind]];
        for (let node of result) {
            result.push(...findAllElementsRecursive(node, tagToFind));
        }
        return result;
    }
    const result = [parent[tagToFind]];
    result.push(...findAllElementsRecursive(parent[tagToFind], tagToFind));
    return result;
}

async function prepareSVGDefs(svgElem) {
    let svgDefs = svgElem.defs;

    if (typeof svgDefs === "object") {
        if (typeof svgDefs.style === "string") {
            svgDefs.style = [svgDefs.style];
        } else if (!Array.isArray(svgDefs.style)) {
            svgDefs.style = [];
        }
    } else {
        svgDefs = {
            style: []
        };
        svgElem.defs = svgDefs;
    }

    if (typeof svgDefs.pattern === "undefined") {
        svgDefs.pattern = [];
    }

    return svgDefs;
}

async function patternDefForMissingColor(colorCustomization) {
    return {
        "@_id": `customization-pattern-${colorCustomization.id}`,
        "@_patternUnits": "userSpaceOnUse",
        "@_width": "800",
        "@_height": "800",
        rect: [
            {
                "@_width": "800",
                "@_height": "800",
                "@_fill": "#ff00ff"
            }
        ]
    }
}

async function patternDefForColor(colorCustomization, colorObject) {
    let image = [];
    let rect = [];
    if (colorObject.imageUrl) {
        image.push({
            "@_width": "800",
            "@_height": "800",
            "@_href": colorObject.imageUrl,
            "@_x": "0",
            "@_y": "0"
        });
    } else {
        rect.push({
            "@_width": "800",
            "@_height": "800",
            "@_fill": colorObject.rgb
        })
    }
    return {
        "@_id": `customization-pattern-${colorCustomization.id}`,
        "@_patternUnits": "userSpaceOnUse",
        "@_width": "800",
        "@_height": "800",
        image,
        rect
    }
}

async function generateCustomizationPatternDef(
    colorCustomization,
    appliedColor
) {

    let colorObject = null;
    for (let colorGroup of colorCustomization.color.groups) {
        for (let color of colorGroup.colors) {
            if (color.id === appliedColor) {
                colorObject = color;
                break;
            }
        }
    }

    if (colorObject === null) {
        logWarn(`Could not find color with id ${appliedColor} in color customization ${colorCustomization.id}`);

        return await patternDefForMissingColor(colorCustomization);
    } else {
        return await patternDefForColor(colorCustomization, colorObject)
    }

}

async function generateCustomizationStyleDef(colorCustomization) {
    return `.customization-fill-${colorCustomization.id} { fill: url(#customization-pattern-${colorCustomization.id}); }`;
}

async function addClassToPathElem(pathElem, classToAdd) {
    if (typeof pathElem["@_class"] === "undefined") {
        pathElem["@_class"] = classToAdd;
    } else {
        pathElem["@_class"] += ` ${classToAdd}`;
    }
}

async function applyTransparency(allGElems, colorCustomization) {
    const svgId = colorCustomization.color.selector;
    const matchingGElem = allGElems.find(g => g["@_id"] === svgId);
    if (!matchingGElem) {
        logWarn(`No matching SVG <g> element for selector ${svgId}`);
        return false;
    }

    const pathsInGElem = findAllElementsRecursive(matchingGElem, "path");
    for (let pathElem of pathsInGElem) {
        pathElem["@_style"] = `${pathElem["@_style"]}; fill: transparent`;
        await addClassToPathElem(pathElem, `customization-fill-${colorCustomization.id}`);
    }

    return true;
}

async function applyColorCustomization(
    allGElems,
    svgDefs,
    colorCustomization,
    appliedCustomizations
) {

    const applied = appliedCustomizations.filter(
        ac => ac.id === colorCustomization.id
    );

    if (!applied || applied.length === 0) {
        await applyTransparency(allGElems, colorCustomization);
        logWarn(`No applied customization value for ${colorCustomization.id}`);
        return false;
    }

    const appliedColor = applied[0].color;
    const svgId = colorCustomization.color.selector;

    const matchingGElem = allGElems.find(g => g["@_id"] === svgId);
    if (!matchingGElem) {
        logWarn(`No matching SVG <g> element for selector ${svgId}`);
        return false;
    }

    const patternDef = await generateCustomizationPatternDef(
        colorCustomization,
        appliedColor
    );
    const styleDef = await generateCustomizationStyleDef(colorCustomization);

    svgDefs.pattern.push(patternDef);
    svgDefs.style.push(styleDef);

    const pathsInGElem = findAllElementsRecursive(matchingGElem, "path");
    for (let pathElem of pathsInGElem) {
        pathElem["@_fill"] = `url(#${colorCustomization.id})`;
        await addClassToPathElem(pathElem, `customization-fill-${colorCustomization.id}`);
    }

    return true;

}

async function applyHighlight(
    allGElems,
    svgDefs,
    highlighedCustomization,
    highlightColor
) {

    const svgId = highlighedCustomization.color.selector;

    svgDefs.style.push(`.highlighted-outline-${highlighedCustomization.id} { stroke: ${highlightColor}; stroke-width: 3px; }`);

    const matchingGElem = allGElems.find(g => g["@_id"] === svgId);
    if (!matchingGElem) {
        logWarn(`No matching SVG <g> element for selector ${svgId}`);
        return false;
    }

    const pathsInGElem = findAllElementsRecursive(matchingGElem, "path");
    for (let pathElem of pathsInGElem) {
        pathElem["@_stroke"] = typeof highlightColor === "string" ? highlightColor : "#0000ff";
        pathElem["@_stroke-width"] = `3px`;
        await addClassToPathElem(pathElem, `highlighted-outline-${highlighedCustomization.id}`);
    }

    return true;

}

export async function customizeProductSVG(
    {
        svgContent,
        customizations,
        appliedCustomizations,
        highlightedCustomizations,
        highlightColor
    }
) {

    const xmlParser = new XMLParser({
        ignoreAttributes: false
    });
    const svgXml = xmlParser.parse(svgContent);

    const svgElem = svgXml.svg;
    let svgDefs = await prepareSVGDefs(svgElem);

    const allGElems = findAllElementsRecursive(svgElem, "g");
    const colorCustomizations = customizations.filter(c => c.type === "COLOR");

    if (Array.isArray(colorCustomizations) && Array.isArray(appliedCustomizations)) {

        for (let colorCustomization of colorCustomizations) {

            if (!await applyColorCustomization(
                allGElems,
                svgDefs,
                colorCustomization,
                appliedCustomizations
            )) {
                logWarn(`Could not apply color customization ${colorCustomization.id}`);
            }

        }

    }

    if (Array.isArray(highlightedCustomizations)) {

        for (let highlighedCustomization of highlightedCustomizations) {

            if (!await applyHighlight(
                allGElems,
                svgDefs,
                highlighedCustomization,
                highlightColor
            )) {
                logWarn(`Could not apply highlight to customization ${highlighedCustomization.id}`);
            }

        }

    }

    const xmlBuilder = new XMLBuilder({
        ignoreAttributes: false
    });
    const resultSvg = xmlBuilder.build(svgXml);

    return resultSvg;

}