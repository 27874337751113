import getOpt from "@diabfoot/get-opt";

export class OrderClient {
    constructor(opts) {
        this.graphqlClient = getOpt(opts, "graphqlClient");
    }

    async saveOrder(order) {
        const response = await this.graphqlClient.mutate(
            `mutation SaveOrder($order: OrderInput!) {
                order {
                    submit(input: $order) {
                        id
                    }
                }
            }`,
            {
                order
            }
        )
        return response.data.order?.submit;
    }

}