{
  "scaffold": {
    "configurator_header": "Cipőnekem konfigurátor",
    "drorto_website": "Dr. Orto weboldal",
    "customer_service": "Ügyfélszolgálat",
    "language": "Nyelv",
    "english": "Angol",
    "hungarian": "Magyar",
    "női_catalog": "Egyedi női cipők",
    "gyerek_catalog": "Egyedi gyerek cipők",
    "férfi_catalog": "Egyedi férfi cipők",
    "trend_catalog": "Egyedi trend cipők",
    "appointment": "Időpontfoglalás",
    "products": "Termékek",
    "faq": "GYIK",
    "specialty_stores": "Szaküzletek",
    "magazine": "Magazin",
    "impressum": "Impresszum",
    "shoes": "Cipők",
    "insoles": "Talpbetétek",
    "tools-for-foot-problems": "Eszközök lábproblémákra",
    "fasteners": "Rögzítők, kompressziós termékek",
    "lower_limb_prostheses": "Alsó végtak protézisek",
    "custom_made_orthoses": "Egyedi gyártású ortézisek",
    "walking_aids": "Járássegítők",
    "wheelchairs_pushchairs": "Kerekesszékek, babakocsik",
    "services": "Szolgáltatások",
    "personalized_services": "Személyre szabott szolgáltatások",
    "consultancy": "Rehabilitációs és ortopédiai szakorvosi tanácsadás",
    "assessment": "Szűrés és állapotfelmérés",
    "ask_drorto": "Kérdezze Dr Ortót",
    "about_us": "Rólunk",
    "get_to_know_us": "Ismerjen meg minket",
    "career": "Karrier",
    "our_websites": "Weboldalaink",
    "distributed_brands": "Forgalmazott márkák",
    "newsletter": {
      "title": "Értesüljön az újdonságokról első kézből! Íratkozzon fel hírlevelünkre!",
      "name": "Név",
      "email": "E-mail cím",
      "privacy_policy": "Elfogadom az Adatkezelési Tájékoztatóban foglaltakat.",
      "gtc": "Elfogadom az ÁSZF-ben foglaltakat.",
      "subscribe": "Feliratkozom"
    }
  },
  "landing-page": {
    "custom_shoes_header": "Miért kell egyedi cipő?",
    "custom_shoes_text": "A bolti cipők „átlag” lábra készülnek és a divatot követik. A tervezők és értékesítők abban bíznak, hogy a design „elviszi” a terméket, és sok olyan „lábat” fognak találni, ami a divat kedvéért idomul a lábbelihez. A talp alátámasztására pedig egyáltalán nem figyel a divatipar. Ennek következményeként az évek múlásával egyre többen szenvedünk a rossz cipők okozta lábkárosodástól.",
    "custom_shoes_button": "Készítese el személyre szabott cipőjét",
    "custom_womens_shoes": "Egyedi női cipőt szeretnék",
    "custom_mens_shoes": "Egyedi férfi cipőt szeretnék",
    "custom_kids_shoes": "Egyedi gyerek cipőt szeretnék",
    "custom_trend_shoes": "Egyedi trend cipőt szeretnék",
    "how_will_you_have_a_custom_shoe": "Miként lesz egyedi cipője?",
    "details": "Részletek",
    "flow": {
      "1": "Válassza ki az Önnek leginkább tetsző modellcsaládunkat.",
      "2": "Használja a cipőkonfigurátorunkat és állítsa be lába paramétereit, esetleges elváltozásait, betegségeit",
      "3": "Állítsa össze az Önnek tetsző szín és anyagvariációkat, válasszon talpbetétet, majd adja meg lába méreteit.",
      "4": "Véglegesítse megrendeléséet és várjon ügyfélszolgálatunk jelentkezésére, hogy időpontot egyeztessünk Önnel.",
      "5": "Egyeztetett időpontban szaküzletünkben pontosan felmérjük lába paramétereit. (Méretét, állapotát esetleges elváltozásait)",
      "6": "Szakembereink elkészítik az egyedi cipőjét.",
      "7": "Egyeztetett időpontban megtörténik a próba. Ha minden megfelelő, Ön boldog tulajdonosa egyedi cipőjének."
    }
  },
  "product-details": {
    "meet_title": "Ismerje meg a(z) %s% modellcsaládot",
    "your_choice": "Az Ön választása",
    "i_personalize_it": "Személyre szabom",
    "details": "Részletek",
    "custom_shoes_text": "Termékeink teljes mértékben az Ön lábára szabva készülnek el. Méretmintavételt követően szakembereink az Ön igényeire és tüneteinek kezelésére szabva készítik el lábbelijét.",
    "lisbon_b3_15_description":  "A Lisbon B3 speciálisan, magas kéregrésszel kialakított modellcsalád, mely alkalmas lépcsős és egyedi betétek befogadására. Alapvonalai számos kombinációs lehetőséget tartalmaznak, így a család különböző tagjai szinte bármilyen használati elvárást és dizájnigényt is kielégítenek. Erősebb bütyök, kalapácsujj, vagy előrehaladott diabéteszes láb problémákkal küzdőknek kifejezetten ajánljuk a család bármelyik verzióját.",
    "lima_15_description" :  "A Lima az egyik legnépszerűbb, komplex modellcsaládunk. Alapvonalai számos kombinációs lehetőséget tartalmaznak, így a család különböző tagjai szinte bármilyen használati elvárást és dizájnigényt kielégítenek. Erősebb bütyök, kalapácsujj, vagy előrehaladott diabéteszes láb problémákkal küzdőknek kifejezetten ajánljuk a család bármelyik verzióját.",
    "monaco_15_description":  "Éles, mégis kerekded formáival, egyszerű vagy merészen összeállított színkombinációval ez a modell a ruhatár remek kiegészítője lehet. Erről a cipőről már messziről feltűnik, milyen kényelmes. Fűzős és tépőzáras kivitelben is rendelhető, 15 mm sarokmagasságú, rámázott- vagy csészetalppal. Kiváló a diabéteszes láb megóvására, és egyedileg gyártott betéttel is használható.",
    "osaka_15_description":  "Fiatalos sportcipő, amely bármely cipőboltban megállná a helyét. Fűzős vagy tépőzáras verzióban elérhető, 15 mm sarokmagasságú, utcai talppal. A még divatosabb hatás érdekében rendelheti csészetalppal! Egyedileg gyártott betét befogadására és diabéteszes láb megóvására alkalmas.",
    "torino_15_description":  "Alacsony szárú 15 mm-es sarokmagasságú bokacipő melynek rövid feje, előrevezetett fűzőrésze biztosítja a cipő könnyű felvételét. Az oldaldíszpánt egyedi színkombinációja, húzófüles kéregrész megoldása teszi divatossá és fiatalossá a modellt. Téliesített változatban szőrmebéléssel is rendelhető. Alja kialakítása rámázott forma, vagy varrással megerősített csészetalp.",
    "riga_15_description":  "Kényelmes bakancs, a téli mindennapokra, sőt, akár túrázáshoz. Puha, bélelt gallérrészével biztos nem fog benne fázni a lába, de az őszi hónapokra vékonyabb béléssel is igényelhető. Deformált lábra, kialakult bütyök, kalapácsujj esetén is hordható, egyedi betét befogadására alkalmas. Diabéteszes láb megóvására és kezelésére ideális.",
    "oxford_15_description":  "Klasszikus, elegáns cipő, melyet lyukasztott orrdíszítése tesz különlegessé. A húszas éveket idéző lábbeli mély kialakításának köszönhetően ideális a diabéteszes láb megóvására. Egyedi betét befogadására alkalmas, enyhén deformált láb esetén ajánljuk.",
    "bira_15_description":  "Legyen tépőzáras vagy akár fűzős, a Bira tökéletes átmenetet képez a sportcipő és az utcai cipő között. Diabeteszes megrendelők kedvelt cipője lehet. Végső jellegét nagy mértékben meghatározza az anyagválasztás. Fűzős verziója vagány színekkel kifejezetten sportos, fiatalos, míg a tépőzár az idősebbek számára jelenthet jó megoldást. Ez a lábbeli is bátran választható bütyök, kalapácsujj esetén is.",
    "bilbao_15_description":  "A Bilbao modell tökéletes átmenetet képez a sportcipő és az utcai cipő között. Nyelv kialakítésa, szélesebb nyitása, tépőzárral történő záródása miatt a diabeteszes megrendelők kedvelt cipője lehet, Végső jellegét nagy mértékben meghatározza az anyagválasztás. Vagány színekkel kifejezetten sportos, fiatalos, míg a tépőzár az idősebbek számára is jó megoldást jelent. Ez a lábbeli is bátran választható bütyök, kalapácsujj esetén is.",
    "oslo_15_description":  "Magas szárú, fűzős vagy tépőzáras záródású modell,, melyet nem kell kímélni! Kialakításának köszönhetően meglepően könnyű belebújni. Rámával szegélyezett talppal és csészetalppal is rendelhető. Diabéteszes lábbal, deformált lábbal, előrehaladott bütyökkel és kalapácsujjal is hordható, egyedi betét befogadására alkalmas.",
    "berlin_15_description":  "Fejbetétes, fűzőrészen díszpánttal díszített cipőmodell, mely tökéletes átmenetet képez a sportcipő és az utcai cipő között. Végső jellegét az anyagválasztás, illetve az anyagkombináció határozza meg. Vagány színekkel, csészetalppal kifejezetten sportos, fiatalos modellt tudunk kialakítani.",
    "astro_15_description":  "Korcsolya fazonú fűzővel vagy tépőzárral záródó szárascipő. A cipő könnyű bebújását a fej és száralkatrészeket összefogó előrevezetett fűzőrész, divatos sportos jellegét a szivacsos gallárrész biztosítja. A láb oldalának jó megtámasztását az előrevezett kéreg, a felsőrész záródását stabil rögzítését három oválkarikás jó tapadású tépőzár, vagy fűző biztosítja. Alja kialakítása különböző típusú rámával díszített recézett forma vagy varrással megerősített csészetalp",
    "bahama_15_description":  "Városjáráshoz tökéletes, stabil talpú szandál, amely a láb oldalát is jól megtámasztja. Az egyedileg kialakított betét szépen belesimul a modellbe, így ezzel is hordható. A lecsatolható sarokpántnak köszönhetően egy pillanat alatt házi cipővé alakítható. Sarokpánt nélküli kialakítása könnyű bebújást biztosít az otthoni környezetben.",
    "miami_15_description":  "Sportos, tépőzáras, modern szandálcipő, minimalista dizájnnal. Csészetalppal is igényelhető, amely fiatalos külsőt kölcsönöz a lábbelinek. Egyedi talpbetéttel is hordható. Diabéteszes láb megóvására a fejbetétrész osztás nélküli változatát javasoljuk.",
    "macao_15_description":  "Sportos, tépőzáras, modern szandálcipő, minimalista dizájnnal. Csészetalppal is igényelhető, amely fiatalos külsőt kölcsönöz a lábbelinek. Egyedi talpbetéttel is hordható, és a diabéteszes láb megóvására is ajánljuk.",
    "ciprus_15_description":  "A sportos szandálcipő, amely tépőzáras kialakításának köszönhetően a tökéletes, ha reggel gyorsan kint kell lenni az ajtón. Tavaszra készülhet sima fejjel, nyárra szellőző lyukasztással, vagy akár nagy ovális lyukasztással. Talpa lehet rámával díszített 15 mm sarokmagasságú utcai, vagy kissé modernebb stílusú csészetalp. Kifejezetten ajánljuk diabéteszes láb megóvására, egyedi betéthez, és bütykös-kalapácsujjas lábra.",
    "corsica_15_description":  "Az igazi klumpa, melynek még sarokpántja is lehet. A modell készülhet sima fejjel, vagy díszítő lyukasztással, talpa lehet 15 mm sarokmagasságú, rámás, vagy sportos csészetalp. Szinte végtelen színkombinációban összerakható. Egyedi betét befogadására alkalmas, a benne lévő gumibetétnek és egy kis technikai trükknek köszönhetően dagadós lábúaknak is kiváló",
    "sofia_25_description":  "Keresztpántos balerinacipő nyári rohangálásra. Keskenyített orrformájával, 25 mm-es sarokmagasságával és a megfelelő színösszeállítással kifejezetten elegáns is lehet. Kisméretű bütyök és enyhe kalapácsujj esetén ajánljuk, egyedi betét befogadására alkalmas.",
    "elba_15_description":  "Egy különleges, sportos szandál, amely zárt kéregrészes kialakításának köszönhetően egyedi talpbetét, illetve boka lábortézis befogadásárais alkalmas. Pántjainak kialakítása biztosítja a könnyű bebújást, hordás közben pedig a stabil rögzítést. Bátor színkombinációkkal mutat igazán jól.",
    "adana_25_description":  "Klasszikus, elegáns, 25 mm sarokmagasságú félcipő, amely a megfelelő szín- és anyagválasztással minden korosztály számára tökéletes. A modell akár a diabéteszes láb megóvására is alkalmas, enyhe bütyök és kalapácsujj esetén is ajánljuk.",
    "bern_25_description":  "Kissé emelt sarkú, fűzős félcipő, melynek egyediségét a fűzőrészen saját szín- és anyagválasztással növelheti. Bütyök és kalapácsujj esetén se féljen, ezt a modellünket biztosan kényelmesnek találja!",
    "birakan_25_description":  "Sportosan elegáns, fűzővel vagy tépőzárral záródó félcipő. A fűzőrész, a szivacsos gallérrész jól eltalált színkombinációi, valamint a 25 mm sarokmagasságú, különböző típusú rámával díszített, recézett talp harmonikus és nőies külsőt ad a modellnek, melyet enyhe bütyök, kalapácsujj esetén is bátran hordhat.",
    "corfu_15_description":  "Nyitott, oldalt rugalmas gumival ellátott házi cipő, amely egyedi betét befogadására is alkalmas. A 15 mm sarokmagasságú, rámázott talp, valamint a széles tépőzárak egyaránt a stabilitást szolgálják, enyhe bütyök, kalapácsujj esetén is viselhető. Állítható pántjainak köszönhetően kifejezetten jól veszi a lábdagadás akadályait is.",
    "marino_25_description":  "Akár bélelt és téli, akár vékony és nyári, ez a bokacipő bármikor jól jön. 25 mm sarokmagasságú, nőies, rámázott talppal készül. Csatos záródása visszafogott eleganciát kölcsönöz, így nem csak a mindennapokra ideális. Enyhén deformált lábra ajánljuk, egyedi betét befogadására alkalmas.",
    "alta_25_description":  "Fiatalos, fűzős bokacipő. Gallérrésze szabadon választható: lehet lábhoz simuló gumiszalag, vagy szivacsozott, puha bőr kialakítású. Tavasszal sportos és vidám, míg meleg béléssel ősziesebb, téli jellegű bokacipő. A modell 25mm sarokmagasságú, választható színű rámával ellátott talppal készül.",
    "bonn_25_description":  "A modern Bonn egy olyan fűzős dizájn, mely extra mélységekkel rendelkezik, és nem csak a lehetőségek színterén. Kialakításának köszönhetően tökéletes az egyedi betétek befogadására, enyhén deformált láb esetén is hordható. 25 mm sarokmagasságú, rámázott formatalppal készül, megosztott fejrésze kiváló lehetőséget biztosít a szín- és anyagkombinációkra.",
    "dover_25_description":  "Trendi, nőies bokacipő, kissé emelt sarokkal, melyet az elegáns vonalvezetés és a lábhoz simuló gumibetét tesz különlegessé. A több részből összeállítható fej és a fűzőrész együtt kiváló lehetőséget biztosít arra, hogy a színekkel és anyagokkal játszva egy tökéletesen egyedi cipőt tervezzen magának. A modell egyedi betét befogadására alkalmas, de nagyobb bütyök, kalapácsujj esetén nem ajánljuk.",
    "kairo_15_description":  "Szandálcipő szivacsos gallér megoldással és tépős záródással, mely tökéletes a mindennapokra. A választot talp határozza meg, hogy inkább sportosabb vagy elegánsabb legyen a cipő. Egyedi betéttel hordható, normál lábra, illetve kis bütyök esetén ajánljuk",
    "madison_b3_15_description":  "Klasszikus, hétköznapi félcipő mintájára speciálisan, magas kéregrésszel kialakított modellcsalád, mely alkalmas lépcsős és egyedi betétek befogadására. A modellváltozatok fűzős vagy tépőzáras kivitelben, hagyományos, rámás vagy sportos csészetalppal készülnek. Kialakítása diabéteszes, és fekélyes láb megóvására alkalmas.",
    "paris_15_description":  "A modell jellegzetessége a rövid fej, előrevezetett fűzőrész, mely a könnyű bebújást a varrással megerősített csészetalp a sportos megjelenést biztosítja. A szár oldalát tűzés, míg a fűzőrészt kombinációs lehetőséget biztosító csík díszíti. Záródása lehetőséget ad a divatos cipőfűzési variációknak.",
    "hodor_15_description":  "A Hodor speciális záródású, orr-részig lehajtható nyelvrésszel, nagy nyitása révén könnyű bebújást biztosító modellcsalád, mely alkalmas lépcsős és egyedi betétek befogadására. Előrehaladott diabéteszes láb problémákkal küzdőknek kifejezetten ajánljuk a modellt.",
    "emma_25_description":  "Klasszikus ortopédiai szárascipő, fűzős vagy tépős záródássa. Tavaszi nyári változata Veronika néven fejen és száron szellőző lyukasztással és tűzéssel díszített. Őszi téli változata Emma, lyukasztás nélküli szárral, vékony laminált béléssel kérhető. A modellcsalád 25 mm-es sarokmagassággal kényelmes és nőies. Deformált lábra, kialakult bütyök, kalapácsujj esetén is hordható, egyedi betét befogadására alkalmas. Diabéteszes láb megóvására és kezelésére ideális.",
    "izabella_15_description":  "Előrevezetett szármegoldásával jó bebújást biztosító ortopédiai szárascipő, fűzős vagy tépős záródással. A modellcsalád 15 mm-es sarokmagasságú, kényelmes, szárat körülölelő szivacsozott gallérmegoldással. Tavaszi nyári változata Izabella néven fejen és száron szellőző lyukasztással és tűzéssel díszített. Őszi téli változata Isolda, lyukasztás nélküli szárral, vékony laminált, vagy szőrme béléssel is kérhető. Deformált lábra, kialakult bütyök, kalapácsujj esetén is hordható, egyedi betét befogadására alkalmas. Diabéteszes láb megóvására és kezelésére ideális.",
    "rita_15_description":  "Orr-részen nyitott, kéregrészen zárt szandál. A láb oldalának jó megtámasztását a kéreg, a felsőrész záródását stabil rögzítését fűző, vagy három oválkarikás jó tapadású tépőzár biztosítja. Rita néven fűzős változata szellőző díszítő lyukasztással kérhető, Rézi változata síma lyukasztás nélküli kialkítással készül. Bebújáshoz a modell teljesen szétnyitható, így a láb könnyen a cipőbe helyezhető. Alja kialakítása színes rámával kombinálható enyhe gördülővel, előrevezetett sarokmegoldással készül.",
    "blake_15_description":  "A tökéletes szandálcipő szivacsos gallér megoldással, fej felvezető pántján átbújtatatott kettő tépős záródással. Betéttel kényelmes utcai viselet, de kialakítása lehetőséget biztosít a boka lábortézis befogadására. A választot talp határozza meg, hogy inkább sportosabb vagy elegánsabb öltözékhez hordjuk.",
    "genf_15_description":  "A fűzős záródású Genf modell tökéletes átmenetet képez a sportcipő és az utcai cipő között. Diabeteszes megrendelők kedvelt cipője lehet. Végső jellegét nagy mértékben meghatározza az anyagválasztás. Fűzős verziója vagány színekkel kifejezetten sportos, fiatalos, míg a fűzőrész melletti zipzármegoldás a fiatalok és az idősebbek számára is a cipő könnyű felvételét teszi lehetővé. Ez a lábbeli is bátran választható bütyök, kalapácsujj esetén is.",
    "madison_15_description":  "Klasszikus, hétköznapi félcipő, fűzős vagy tépőzáras kivitelben, hagyományos, rámás vagy sportos csészetalppal. Igény szerint sima vagy díszítűzött fejjel, akár szellőző lyukasztással is gyártható. Egyedi lábortézissel is hordható, egyedi betét befogadására alkalmas modell, melyet enyhén deformált lábra, diabéteszes láb megóvására, valamint enyhe bütyök és kalapácsujj esetén ajánlunk.",
    "london_15_description":  "Klasszikus fejbetétes bebújos, szivacsozott gallérmegoldással készülő sportos, mégis elegáns félcipő. Két oldalán levő gumicúg biztosítja, hogy a cipő szépen a lábra símuljon. Aljakialakítása a felsőrésszel harminizáló ráma, 15mm sarokmagasságú enyhe gördülővel ellátott formatalp.",
    "denver_15_description": "Egyszerűen elegáns bőrcipő, amely bármikor, bármilyen helyzetben megállja a helyét. Deformált lábra, valamint diabéteszes láb megóvására és kezelésére is tökéletes, és kialakult bütyök és kalapácsujj esetén is ajánljuk. Egyedi betét befogadására alkalmas.",

    "maffia_7_description":  "Magasított szárú, könnyen lábra adható bokacipő modell. Felsőrész kialakítása színkombinációs lehetőséggel egyedivé varázsolható. A modell felsőrésze szivacsos gallérkialakítású, Három oválkarikás tépőzár megoldása stabil rögzítást biztosít. Alja kialakítása egyszínű, vagy színes cérnával varrott rámás, formára alakított előre vezetett sarkú talp, vagy sportos változatban csészetalp.",
    "kioto_7_description":  "Speciális előrevezetett fűzőrész és rövid fejmegoldás biztosítja a könnyű bebújást. A modell szárzáródása fűzős, vagy tépőzáras kialakítású, Alja szerkezete 7mm-es sarokmagasságú rámázott forma, vagy sportos csészetalp.",
    "verona_7_description":  "Alacsony szárú bokacipő melynek rövid feje, előrevezetett fűzőrésze biztosítja a cipő könnyű felvételét. Az oldaldíszpánt egyedi színkombinációja, húzófüles kéregrész megoldása teszi divatossá és fiatalossá a modellt. Téliesített szőrmebéléssel is rendelhető a sportfűzős záródású Verona modell. Alja kialakítása rámázott forma, vagy varrással megerősített csészetalp.",
    "tallin_7_description":  "Kényelmes bakancs, a téli mindennapokra, sőt, akár túrázáshoz. Puha, bélelt gallérrészével biztos nem fog benne fázni a lába, de az őszi hónapokra vékonyabb béléssel.is készíthető. Fűzős és tépőzáras változatban csészetalppal, illetve rámázott formatalppal kérhető. Egyedi betét vagy lábortézis befogadására alkalmas.",
    "bora_7_description": "Tépőzáras vagy fűzős, a Bora tökéletes átmenetet képez a sportcipő és az utcai cipő között. Végső jellegét nagy mértékben meghatározza az anyagválasztás. Fűzős verziója vagány színekkel kifejezetten sportos, fiatalos, míg a tépőzár könnyebb rögzítése jelenthet jó megoldást..",
    "donald_7_description":  "Speciális előrevezetett fűzőrész és rövid fejmegoldás biztosítja a könnyű bebújást. A modell fűzős szárzáródása, gallérrészen korcsolyakapoccsal fejeződik be. Modell külső oldalát három színű csík díszíti. A cipő belsejében átvezetett, külső oldalon csatolódó pánt lépéskor stabilan rögzíti a lábat. Kérhető száron elhelyezett második dísz csatos változatban is. Alja kialakítása rámával , belső oldalon előrevezetett sarokkal ellátott formatalp.",
    "tom_for_afo_7_description":  "Sportos félcipő, szártető részen lábat körülölelő szélesebb szivacsgallérral, mely egyszerre biztosítja a sportos jelleget és a kényelmet. Felsőrész záródása fűzős és tépőzáras kialakítással kérhető. A cipő könnyű bebújását a hosszan előrevezetett szár kialakítása teszi lehetővé. A modell járást segítő ortopédiai eszköz befogadására alkalmas. A cipő díszítését az oldalán levő díszcsíkok és az alkatrészek színkombinációja biztosítja. Alja kialakítása egyszínű, vagy színes cérnával varrott ráma, formára alakított előre vezetett sarkú talppal.",
    "hamburg_7_description":  "Vagány színekkel, csészetalppal kifejezetten sportos, rámázott formatalppal normál utcai jellegű modell. Fejbetétes, fűzőrészen díszpánttal díszített, mely tökéletes átmenetet képez a sportcipő és az utcai cipő között. Végső jellegét az anyagválasztás, illetve az anyagkombináció határozza meg.",
    "tiger_for_afo_7_description":  "Kéregrészes, orr-részen nyitott száras szandál. Egyedi szártető kialakítása biztosítja a cipőben hordott eszköz megfelelő használatát. A felsőrész záródását, stabil rögzítését három oválkarikás jó tapadású tépőzár biztosítja. Bebújáshoz a modell teljesen szétnyitható, így a láb az eszközzel együtt könnyen a cipőbe helyezhető. Alja kialakítása színes rámával kombinálható enyhe gördülővel, előrevezetett sarokmegoldással készül.",
    "frici_7_description":  "Három tépőpántos, valamint a cipő belsejében átvezetett külső oldalon csatolódó záródású magasított szárú, kéregrészen zárt, orr-részen nyitott szandál. A tépőpántok mellett a csatolópánt lépéskor stabil rögzítést biztosít. Bebújáshoz a modell teljesen szétnyitható, így a láb könnyen a cipőbe helyezhető. Alja kialakítása színes rámával kombinálható enyhe gördülővel, előrevezetett sarokmegoldással készül.",
    "findusz_7_description":  "Hátul oválkarikás tépőzárral záródó, elől fűzőrészen bőségben állítható specialis kialakítású modell. Kéregrészen teljesen kinyitható, így eszközzel együtt viselve is könnyen lábraadható. Szártetőn a szivacsgallér körül öleli a lábat, oldalán a sportos rusztikus tűzések teszik a modellt különlegessé. Alja kialakítása díszítő rámával szegélyezett jó kopásállóságú formatalp.",
    "szaffi_7_description":  "Orr-részen nyitott, kéregrészen zárt száras szandál. A láb oldalának jó megtámasztását az előrevezett kéreg, a felsőrész záródását stabil rögzítését három oválkarikás jó tapadású tépőzár biztosítja. Bebújáshoz a modell teljesen szétnyitható, így a láb könnyen a cipőbe helyezhető. Alja kialakítása színes rámával kombinálható enyhe gördülővel, előrevezetett sarokmegoldással készül.",
    "tirana_7_description":  "Keresztpántos balerinacipő anyagösszetételétől függően, alkalomra vagy nyári rohangálásra. Kerek orrformájával, 7 mm-es sarokmagasságával és a megfelelő színösszeállítással kifejezetten elegáns is lehet. Szárhátulja kialakítása egyedi betét befogadására is alkalmassá teszi.",
    "barbaro_7_description":  "Orr-részen nyitott, kéregrészen zárt száras szandál. A láb oldalának jó megtámasztását az előrevezett kéreg, a felsőrész záródását stabil rögzítését négy oválkarikás jó tapadású tépőzár, vagy fűző biztosítja. Bebújáshoz a modell teljesen szétnyitható, így a láb könnyen a cipőbe helyezhető. Alja kialakítása színes rámával kombinálható enyhe gördülővel, előrevezetett sarokmegoldással készül.",
    "safranek_7_description":  "Kéregrészes, szároldalán osztott, orr-részen nyitott szandál. A szároldalak osztása színkombinációval sokszínű modellkialakítást tesz lehetővé.A láb oldalának jó megtámasztását az előrevezett kéreg, a felsőrész záródását kettő csat, bokarészen oválkarikás jó tapadású tépőzár biztosítja. Bebújáshoz a modell teljesen szétnyitható, vagy az első csat bőségnek megfelelően beállva a láb egyszerű, könnyű bebújását teszi lehetővé. Alja kialakítása színes rámával kombinálható enyhe gördülővel, előrevezetett sarokmegoldással készül. Rámázott, formára alakított talpmegoldással készül.",
    "lucifer_7_description":  "Orr-részen és kéregrészen zárt száras szandálcipő. A láb oldalának jó megtámasztását az előrevezett kéreg, a felsőrész záródását stabil rögzítését kettő oválkarikás jó tapadású tépőzár biztosítja. Bebújáshoz a modell egészen az orr-részig szétnyitható, így a láb könnyen a cipőbe helyezhető. Alja kialakítása színes rámával kombinálható enyhe gördülővel, előrevezetett sarokmegoldással készül.",
    "senlis_7_description":  "A modell jellegzetessége a rövid fej, előrevezetett fűzőrész, mely a könnyű bebújást a varrással megerősített csészetalp a sportos megjelenést biztosítja. A szár oldalát tűzés, míg a fűzőrészt kombinációs lehetőséget biztosító csík díszíti. Záródása lehetőséget ad a divatos cipőfűzési variációknak.",
    "graz_7_description":  "A fűzős záródású Graz modell tökéletes átmenetet képez a sportcipő és az utcai cipő között. Fűzős verziója vagány színekkel kifejezetten sportos, míg a fűzőrész melletti zipzármegoldással a cipő felvétel gyors és praktikus. Ez a lábbeli a fiatalok kedvelt lábbelije lehet,melynek jellegét nagy mértékben meghatározza az anyagválasztás.",
    "pongo_7_description":  "Alacsony szárú bokacipő előrevezetett fűzőrésszel, mely biztosítja a cipő könnyű felvételét. Szár kialakítása széles szivacsozott gallérrésze ,egyedi színkombinációja teszi divatossá és fiatalossá a modellt. A modell téliesített szőrmebéléssel is rendelhető. A sportfűző, esetleg bokazáródásnál tépőzáras megoldás stabil rögzítést biztosít. Alja kialakítása rámázott forma, vagy varrással megerősített csészetalp.",
    "orion_7_description":  "Könnyen lábraadható szárascipő modell. felsőrész kialakítása színkombinációs díszítést tesz lehetővé.A láb oldalának jó megtámasztását az előrevezett kéreg, a felsőrész záródását stabil rögzítését három oválkarikás jó tapadású tépőzár, vagy fűző biztosítja. Alja kialakítása színes rámával kombinálható formatalp, vagy sportos csészetalp.",
    "helsinki_7_description":  "Magasított szárú, könnyen lábra adható szárascipő modell. Felsőrész kialakítása színkombinációs lehetőséggel egyedivé varázsolható. A modell felsőrésze szivacsos gallérkialakítású, Négy oválkarikás tépőzár megoldása,vagy sportos fűzője stabil rögzítást biztosít. Alja kialakítása egyszínű, vagy színes cérnával varrott rámás, formára alakított előre vezetett sarkú talp.",
    "bedford_7_description":  "Klasszikus, elegáns 7mm-es sarokmagasságú alkalmi cipőként is viselhető modell, melyet lyukasztott kapli és kéregrész díszítése tesz különlegessé. Kialakítása keskeny rámával díszített formatalp.",
    "bruno_7_description":  "Alacsony szárú bokacipő előrevezetett fűzőrésszel, mely biztosítja a cipő könnyű felvételét. Szár kialakítása széles szivacsozott gallérrésze ,egyedi színkombinációja teszi divatossá és fiatalossá a modellt. A modell téliesített szőrmebéléssel is rendelhető. A sportfűző, illetve a tépőzáras megoldás stabil rögzítést biztosít. Alja kialakítása rámázott forma, vagy varrással megerősített csészetalp.",
    "cliff_7_description":  "Orr-részen és kéregrészen zárt száras szandálcipő, felvezetett díszítő lyukasztással ellátott fejrészéhez kapcsolódnak a tépőpántok. A láb oldalának jó megtámasztását az előrevezett kéreg, a felsőrész záródását stabil rögzítését tépőzár biztosítja. Bebújáshoz a modell jól szétnyitható, így a láb könnyen a cipőbe helyezhető. Alja kialakítása színes rámával kombinálható enyhe gördülővel, előrevezetett sarokmegoldással készül.",
    "rocko_7_description":  "Fejrészen és kéregrészen zárt, tépőzáras szandálcipő, melynek felvezetett fejközép része díszítő lyukasztással ellátott. A fej és a szár találkozását oldalcsík díszíti. A sok alkatrészből álló Rocko modellnél a színkombinációk lehetősége végtelen. A láb oldalának jó megtámasztását az előrevezett kéreg, a felsőrész záródását stabil rögzítését tépőzár biztosítja. Alja kialakítása színes rámával kombinálható enyhe gördülővel, előrevezetett sarokmegoldással készül."
  },
  "product-filters": {
    "categories": "Categories",
    "diabetes": "Diabetes",
    "sports": "Sports",
    "standing": "For people standing at work",
    "everyday": "For everyday life",
    "symptom": "Vannak lábproblémáim",
    "risc_factor_message": "A végső modellválasztáshoz és méretvételhez szakember szükséges. Foglaljon időpontot!"
  },
  "flow": {
    "model_families": "Modellcsaládok",
    "customization": "Személyre szabás",
    "insole": "Talpbetét ",
    "foot_dimensions": "Láb méretek",
    "finalize_request": "Igénylés véglegesítése",
    "restart": "Újrakezdem",
    "back": "Vissza",
    "next": "Tovább",
    "colorer": {
      "info": "Kattintson egy alkatrészre a színezéshez!",
      "parts": {
        "fej":"fej",
        "szár":"szár",
        "kéregrész és baba/húzófül":"kéregrész és baba/húzófül",
        "kéregrész toldás":"kéregrész toldás",
        "gallér/gallértoldás":"gallér/gallértoldás",
        "tépőpántok oválkarika tartóval":"tépőpántok oválkarika tartóval",
        "alkatrész bélés":"alkatrész bélés",
        "főrész bélés":"főrész bélés",
        "CS15":"CS15",
        "ráma":"ráma",
        "U15":"U15",
        "acéllágyék":"acéllágyék",
        "baba/húzófül":"baba/húzófül",
        "Bayer rugó":"Bayer rugó",
        "bélés egyéb":"bélés egyéb",
        "beragasztó":"beragasztó",
        "Berlini kéreg":"Berlini kéreg",
        "bokaszivacs":"bokaszivacs",
        "bőrfoglaló":"bőrfoglaló",
        "bőrtalp":"bőrtalp",
        "cérna":"cérna",
        "CS20":"CS20",
        "CS7":"CS7",
        "CS7 járó":"CS7 járó",
        "csat":"csat",
        "csatpánt 2 csatolópánttal":"csatpánt 2 csatolópánttal",
        "csatpántok csattartóval":"csatpántok csattartóval",
        "Dioryt":"Dioryt",
        "díszcsík 1":"díszcsík 1",
        "díszcsík 2":"díszcsík 2",
        "díszcsík 3":"díszcsík 3",
        "díszítés figura":"díszítés figura",
        "díszpánt oválkarikatartóval":"díszpánt oválkarikatartóval",
        "döntés":"döntés",
        "éksarok":"éksarok",
        "emelés":"emelés",
        "erősített kéreg":"erősített kéreg",
        "EVA beragaszó":"EVA beragaszó",
        "fej és nyelv":"fej és nyelv",
        "fejkarika":"fejkarika",
        "fejközép":"fejközép",
        "fejközép és nyelv":"fejközép és nyelv",
        "fejpánt 1":"fejpánt 1",
        "fejpánt 1 és orrbevonó":"fejpánt 1 és orrbevonó",
        "fejpánt 2":"fejpánt 2",
        "fejpánt 2 és toldás":"fejpánt 2 és toldás",
        "foglaló":"foglaló",
        "foglaló, szabott":"foglaló, szabott",
        "fűzörész":"fűzörész",
        "fűzőrész dísz":"fűzőrész dísz",
        "gallér":"gallér",
        "gallérbélés":"gallérbélés",
        "gallérszivacs":"gallérszivacs",
        "gallértoldás":"gallértoldás",
        "gördülő":"gördülő",
        "gördülő rátett saroknál":"gördülő rátett saroknál",
        "gumi":"gumi",
        "GY7":"GY7",
        "hosszított éksarok":"hosszított éksarok",
        "járótalp":"járótalp",
        "kapli":"kapli",
        "kaplirész":"kaplirész",
        "kapocs":"kapocs",
        "kapta építés":"kapta építés",
        "kéreg":"kéreg",
        "kéregrész":"kéregrész",
        "kierősítő":"kierősítő",
        "kispánt":"kispánt",
        "közbélés":"közbélés",
        "köztalp":"köztalp",
        "MEMORY lépcsős betét":"MEMORY lépcsős betét",
        "N25":"N25",
        "nitt":"nitt",
        "nyelv":"nyelv",
        "nyelvbélés":"nyelvbélés",
        "nyelvdísz":"nyelvdísz",
        "nyelvszivacs":"nyelvszivacs",
        "orrbevonó":"orrbevonó",
        "orrboríték":"orrboríték",
        "oválkarika":"oválkarika",
        "patent":"patent",
        "ragasztó":"ragasztó",
        "ringli":"ringli",
        "sarok":"sarok",
        "sarokbevonó":"sarokbevonó",
        "sarokjáró":"sarokjáró",
        "szár belső":"szár belső",
        "szár külső":"szár külső",
        "szárdísz":"szárdísz",
        "szártoldás":"szártoldás",
        "szendvics betét":"szendvics betét",
        "talp merevítő":"talp merevítő",
        "tépő pántvég":"tépő pántvég",
        "tépőpántok oválkarika tartóval és pántvéggel":"tépőpántok oválkarika tartóval és pántvéggel",
        "tépőzár bolyhos":"tépőzár bolyhos",
        "tépőzár horgos":"tépőzár horgos",
        "textilszalag":"textilszalag",
        "U7":"U7",
        "vastag puha betét":"vastag puha betét",
        "zippzár":"zippzár",
        "zippzártakaró":"zippzártakaró",
        "zipzártakaró":"zipzártakaró"
      },
      "colors": {

      }
    },
    "insoles": {
      "DR_ORTO_COMFORT_PU_B1": "Azoknak, akik vigyázni szeretnének a lábukra, kezdeti stádiumban levő diabéteszük van, vagy átlagos komfort cipő gyári betétjét szeretnék helyettesíteni vele. Antibakteriális, nedvtaszító alcantara jellegű fedőrétegével kényelmes és puha talpbetét.",
      "DR_ORTO_DIAB_MEMORY_PLUS_PU_B2": "Csiszolással adaptálható hossz és harántboltozati emeléssel készült puha betét diabéteszes lábra. Elegáns, puha, antibakteriális tulajdonságokkal rendelkező betét.",
      "DR_ORTO_DIAB_MEMORY_EXTRA_B3": "A speciális talpbetét fekély kezelésére, a metatarsatus fejecsek tehermentesítésére alkalmas. Olyan diabéteszeseknek javasolt, akiknek már seb alakult ki a lábán.",
      "choose_this": "Ezt választom",
      "insole_selection": "Talpbetét kiválasztása"
    },
    "sizes": {
      "main_text": "Annak érdekében, hogy lábbelije tényleg az Ön lábához legyen igazítva, szükséges, hogy annak pontos méreteit megadja. Amennyiben összetettebb lábproblémával kűzd és szakemberaink segítségét szeretné kérni lába méreteinek levételéhez, mert ezt érezné biztonságosnak, folytassa megrendelését méretmegadás nélkül. A megrendelés végénél lehetősége lesz időpontot foglalni méretvételi pontjaink egyikébe.",
      "image_upload_text": "Lehetősége van lábáról készül fotó csatolása a magrendeléshez. Amennyiben szeretne ezzel a lehetőséggel élni, kérjük válassza ki a feltölteni kívánt képeket a képek tallózása gombra katintva.",
      "browse": "Képek tallózása",
      "sizing": "Méretmegadás",
      "foot_length_left": "Lábhossz bal",
      "foot_length_right": "Lábhossz jobb",
      "foot_length_text": "Sarkát támassza egy függőleges felülethez, pl. falhoz. Mérje le a fal és a leghosszabb lábujja közötti távolságot. Legpontosabban a leghosszabb lábujjához illesztett kartondoboz segítségével tudja ezt bejelölni.",
      "bunion_width_left": "Bütyökbőség bal",
      "bunion_width_right": "Bütyökbőség jobb",
      "bunion_width_text": "A mérőszalagot a külső, belső illetve a felső bütyökponton keresztül mért körméret milliméterben.",
      "bridge_width_left": "Marbőség bal",
      "bridge_width_right": "Marbőség jobb",
      "bridge_width_text": "Marponton illetve a láb belső boltozatának legmagasabb pontján keresztül mért kerület mm-ben.",
      "heel_width_left": "Sarokbőség bal",
      "heel_width_right": "Sarokbőség jobb",
      "heel_width_text": "A lábfej hajláspontján és a sarokbőség mérőpontján mért kerület mm-ben."
    },
    "order": {
      "your_request": "Az ön igénylése",
      "selected_model": "Kiválasztott modell",
      "materials": "Anyagok",
      "measurements_left": "Méretek bal lábon",
      "measurements_right": "Méretek jobb lábon",
      "insole": "Betét",
      "illness": "Betegség",
      "bottom_text": "Az itt fel nem tűntetett részek a modellhez színben illeszkedő anyagokkal kerülnek elkészítésre!",
      "sizes": {
        "leftLength": "Lábhossz bal",
        "rightLength": "Lábhossz jobb",
        "leftBunnionWidth": "Bütyökbőség bal",
        "rightBunnionWidth": "Bütyökbőség jobb",
        "leftBridgeSize": "Marbőség bal",
        "rightBridgeSize": "Marbőség jobb",
        "leftHeelCircumference": "Sarokbőség bal",
        "rightHeelCircumference": "Sarokbőség jobb"
      },
      "finalize_request": "Igénylés véglegesítése"
    },
    "contact-form": {
      "confirmation_of_request": "Igénylés megerősítése",
      "request_details": "Igénylés részletei",
      "required_fields": "A csillaggal jelölt mezők kitöltése kötelező a továbbhaladáshoz",
      "top_text": "Kérjük adja meg nevét, email címét és telefonszámát. Kérjük olyan elérhetőséget adjon meg, melyen munkaidőben is elérhető.",
      "gtc": "Elfogadom az <a href='/general-business-terms.pdf' target='_blank'>Adatkezelési Tájékoztatóbal</a> foglaltakat",
      "email_info": "Szeretne e-mailben tájékoztatást kapni az egészségügyi ellátást érintő változásokról, aktualitásokról?",
      "subscribe": "Feliratkozom",
      "privacy_policy": "Elfogadom az ÁSZF-ben foglaltakat.",
      "submit_your_request": "Megrendelem",
      "name": "Név",
      "email": "E-mail cím",
      "phone": "Telefonszám",
      "comment": "Megjegyzés"
    }
  },
  "file_upload": {
    "back": "Mégsem",
    "upload": "Feltöltöm a(z) %s% db képet",
    "max_count": "*Maximum fájlméret: 1 Mb, Maximum képszám: %s% db"
  }
}