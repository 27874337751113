<template>
    <div class="insoles">
        <p v-if="error">Hiba történt: {{ error }}</p>
        <loading v-if="loading"></loading>
        <div class="insoles-content" v-else>
            <div class="header-line">
                <h3>{{ $t('insole_selection') }}</h3>
                <div class="orange-line"></div>
                <button @click="resetStep">{{ $i18n.t('flow.restart') }}</button>
            </div>
            <div class="columns">
                <div class="column is-2"></div>
                <div class="column" v-for="option of options" :key="option.id">
                    <card-item image style="min-height: 585px;">
                        <template #card-image>
                            <figure class="image">
                                <img :src="option.imageUrl" />
                            </figure>
                        </template>
                        <template #card-content>
                            <h6 class="title is-6">{{ option.name }}</h6>
                            <p>
                                {{ $t(option.id) }}
                            </p>
                            <button v-if="option.id !== 'DR_ORTO_DIAB_MEMORY_EXTRA_B3'" @click="onChooseOption(option.id)">
                                {{ $t('choose_this') }}
                            </button>
                        </template>
                    </card-item>
                </div>
                <div class="column is-2"></div>
            </div>

            <div class="is-flex is-justify-content-center is-align-items-center">
                <button class="button is-rounded" @click="onBack">{{ $i18n.t('flow.back') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import "./insole.scss";

import CardItem from "@diabfoot/component-card";
import { customizerClient } from "@diabfoot/client-cache";
import Loading from "@diabfoot/component-loading/Loading";

export default {
    components: { CardItem, Loading },
    props: {
        productId: String
    },
    data() {
        return {
            loading: true,
            error: null,
            customization: null,
            options: []
        }
    },
    async mounted() {
        
        const customizations = await customizerClient.listCustomizations({
            productId: this.productId,
        });
        
        const insoleCustomization = customizations.find(
            cust => cust.type === "OPTION_IMAGE"
        );

        if(insoleCustomization) {
            this.options = insoleCustomization.options;
            this.customization = insoleCustomization;
        } else {
            this.error = "Nem található talpbetét típusú testreszabási lehetőség a termékhez!";
        }

        this.loading = false;

    },
    methods: {
        async onBack() {
            this.$emit("back");
        },
        async onChooseOption(optionId) {
            this.$emit("finish-customization", [
                {
                    customizationId: this.customization.id,
                    optionId: optionId,
                }
            ]);
        },
        resetStep() {
            this.$emit('reset');
        },
        $t(key){
            return this.$i18n.t(`flow.insoles.${key}`);
        }
    },
};
</script>

<style scoped></style>
