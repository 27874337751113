<template>
    <div class="product-svg-preview">
        <p v-if="loading">Loading ...</p>
        <div v-html="svgContent" v-else></div>
    </div>
</template>

<script>
import {customizerClient} from "@diabfoot/client-cache"
import {customizeProductSVG} from "@diabfoot/product-svg-customizer"
export default {
    props: {
        productId: String,
        customizations: Array,
        appliedCustomizations: Array
    },
    data() {
        return {
            loading: true,
            svgContent: null
        }
    },
    async mounted() {
        
    },
    async created() {
        
        const rawSvgContent = await customizerClient.getProductSVG({
            productId: this.productId
        });
        const svgContent = await customizeProductSVG({
            svgContent: rawSvgContent,
            customizations: this.customizations || [],
            appliedCustomizations: this.appliedCustomizations || []
        });
        this.svgContent = svgContent;
        this.loading = false;

        this.$nextTick(() => {
            this.$emit("loaded");
        });

    }
}
</script>

<style>

</style>