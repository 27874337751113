<template>
    <div class="tabs-view">
        <div class="tabs">
            <ul>
                <slot></slot>
            </ul>
        </div>
        <component :is="selectedTabComponent" :key="selectedTabId" v-if="selectedTabComponent"></component>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectedTabId: null,
            tabs: [],
            selectedTabComponent: null
        }
    },
    provide() {
        return {
            tabsView: this
        }
    },
    methods: {
        async selectTab(tabId) {
            const tab = this.tabs.find(tab => tab.tabId === tabId);
            this.selectedTabId = tabId;
            this.selectedTabComponent = tab.$slots.default;
            this.$emit("tab-selected", tabId);
        },
        async registerTab(tab) {
            this.tabs = [...this.tabs, tab];
            if(this.selectedTabId === null) {
                await this.selectTab(tab.tabId);
            }
        }
    }
}
</script>