<template>
    <div class="tags catalogue-filters-tags">
        <h2>{{ $t('categories') }}</h2>
        <div class="field is-grouped is-grouped-multiline">
            <div class="tags has-addons" v-for="filter in filters" :key="filter.id">
                <span class="tag" @click="onFilter($event, filter.id)">
                    {{ filter.name }}
                </span>
                <a class="tag is-delete is-hidden" @click="onDeleteFilter($event, filter.id)"></a>
            </div>
        </div>
    </div>
    <div class="tags catalogue-filters-tags">
        <h2>{{ $t('symptom') }}</h2>
        <div class="accordionItem close" v-for="(illness, key) in illnessQuestions" :key="key">
            <button class="title" @click="toggleItem">
                {{ translateIllnessQuestions(illness.label) }}</button>
            <div class="expand-content">
                <ul>
                    <li v-for="children in illness.children">
                        <input type="checkbox" v-model="selectedIllnesses" @change="save" :value="{
                    id : children.key,
                    parent: translateIllnessQuestions(illness.label),
                    label: translateIllnessQuestions(children.label),
                    riscFactor: children.riscFactor
                }">
                        {{ translateIllnessQuestions(children.label) }}
                    </li>
                    <li v-for="images in illness.images" style="margin-bottom: 20px">
                        <img :src="`https://storage.googleapis.com/ciponekem_assets/pictograms/${images.data}`" height="150" width="100"/><br />
                        <input type="checkbox" v-model="selectedIllnesses" @change="save" :value="{
                    id: images.key,
                    parent: translateIllnessQuestions(illness.label),
                    label: translateIllnessQuestionImages(images.label),
                    riscFactor: images.riscFactor
                }" :id="`images-${images.label}`"><label :for="`images-${images.label}`">{{translateIllnessQuestionImages(images.label)}}</label>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

import './catalogue-filters.scss';
import i18Values from './assets/i18n.en.json';
import i18ValuesHu from './assets/i18n.hu.json';
import { productFilterClient, catalogueFilterClient } from "@diabfoot/client-cache";

export default {
    data() {
        return {
            illnessQuestions: [
                {
                    "key": 2,
                    "label": "cukorbeteg",
                    "riscFactor": 0, "criteria": null,
                    "children": [
                        {
                            "key": 3,
                            "label": "morethan7years",
                            "riscFactor": 1,
                            "criteria": null,
                            "children": [],
                            "images": [],
                            "isParent": false
                        },
                        {
                            "key": 4,
                            "label": "erzeszavar",
                            "riscFactor": 1,
                            "criteria": null,
                            "children": [],
                            "images": [],
                            "isParent": false
                        },
                        {
                            "key": 5,
                            "label": "fekelycukorbeteg",
                            "riscFactor": 3,
                            "criteria": null,
                            "children": [],
                            "images": [],
                            "isParent": false
                        }
                    ],
                    "images": [],
                    "isParent": true
                },
                {
                    "key": 6,
                    "label": "kronikusbetegseg",
                    "riscFactor": 0,
                    "criteria": null,
                    "children": [
                        {
                            "key": 11,
                            "label": "izuletizsugorodas",
                            "riscFactor": 2,
                            "criteria": null,
                            "children": [],
                            "images": [],
                            "isParent": false
                        }, {
                            "key": 12,
                            "label": "reumasbetegseg",
                            "riscFactor": 2,
                            "criteria": null,
                            "children": [],
                            "images": [],
                            "isParent": false
                        }, {
                            "key": 13,
                            "label": "limfodema",
                            "riscFactor": 2,
                            "criteria": null,
                            "children": [],
                            "images": [],
                            "isParent": false
                        }, {
                            "key": 7,
                            "label": "erszukulet",
                            "riscFactor": 1,
                            "criteria": null,
                            "children": [],
                            "images": [],
                            "isParent": false
                        }, {
                            "key": 8,
                            "label": "erzeszavar",
                            "riscFactor": 1,
                            "criteria": null,
                            "children": [],
                            "images": [],
                            "isParent": false
                        }, {
                            "key": 9,
                            "label": "seb",
                            "riscFactor": 3,
                            "criteria": null,
                            "children": [],
                            "images": [],
                            "isParent": false
                        }, {
                            "key": 10,
                            "label": "benulasos",
                            "riscFactor": 2,
                            "criteria": null,
                            "children": [],
                            "images": [],
                            "isParent": false
                        }], "images": [], "isParent": true
                },
                {
                    "key": 14,
                    "label": "labdeformitas",
                    "riscFactor": 0,
                    "criteria": null,
                    "children": [],
                    "images": [{
                        "key": 11,
                        "data": "karomallas_ujj.png",
                        "riscFactor": 1,
                        "label": "karomallas_ujj",
                        "type": "url"
                    }, {
                        "key": 12,
                        "data": "labujjak_kozti_tyukszem.png",
                        "riscFactor": 1,
                        "label": "tyukszem",
                        "type": "url"
                    }, {
                        "key": 6,
                        "data": "kisbutyok.png",
                        "riscFactor": 1,
                        "label": "kisbutyok",
                        "type": "url"
                    }, {
                        "key": 13,
                        "data": "labujjon_feluli_tyukszem.png",
                        "riscFactor": 1,
                        "label": "labujjon_feluli_tyukszem",
                        "type": "url"
                    }, {
                        "key": 7,
                        "data": "belso_oldali_butyok.png",
                        "riscFactor": 1,
                        "label": "belso_oldali_butyok",
                        "type": "url"
                    }, {
                        "key": 14,
                        "data": "talp_tyukszem.png",
                        "riscFactor": 1,
                        "label": "talp_tyukszem",
                        "type": "url"
                    }, {
                        "key": 8,
                        "data": "kulso_oldali_butyok.png",
                        "riscFactor": 1,
                        "label": "kulso_oldali_butyok",
                        "type": "url"
                    }, {
                        "key": 15,
                        "data": "benott_korom.png",
                        "riscFactor": 1,
                        "label": "benott_korom",
                        "type": "url"
                    }, {
                        "key": 9,
                        "data": "ketoldali_butyok.png",
                        "riscFactor": 1,
                        "label": "ketoldali_butyok",
                        "type": "url"
                    }, {
                        "key": 16,
                        "data": "dongalab.png",
                        "riscFactor": 1,
                        "label": "dongalab",
                        "type": "url"
                    }, {
                        "key": 10,
                        "data": "kalapacsujj.png",
                        "riscFactor": 1,
                        "label": "kalapacsujj",
                        "type": "url"
                    }],
                    "isParent": true
                },
                {
                    "key": 21,
                    "label": "borbetegseg",
                    "riscFactor": 0,
                    "criteria": null,
                    "children": [{
                        "key": 25,
                        "label": "ekcema",
                        "riscFactor": 1,
                        "criteria": null,
                        "children": [],
                        "images": [],
                        "isParent": false
                    }, {
                        "key": 22,
                        "label": "seb",
                        "riscFactor": 3,
                        "criteria": null,
                        "children": [],
                        "images": [],
                        "isParent": false
                    }, {
                        "key": 23,
                        "label": "borpotlas",
                        "riscFactor": 2,
                        "criteria": null,
                        "children": [],
                        "images": [],
                        "isParent": false
                    }, {
                        "key": 24,
                        "label": "gombasodas",
                        "riscFactor": 1,
                        "criteria": null,
                        "children": [],
                        "images": [],
                        "isParent": false
                    }],
                    "images": [],
                    "isParent": true
                },
                {
                    "key": 26,
                    "label": "specialisellatas",
                    "riscFactor": 0,
                    "criteria": null,
                    "children": [{
                        "key": 27,
                        "label": "hagyomanyosortezis",
                        "riscFactor": 3,
                        "criteria": null,
                        "children": [],
                        "images": [],
                        "isParent": false
                    }, {
                        "key": 30,
                        "label": "csonkoltlab",
                        "riscFactor": 3,
                        "criteria": null,
                        "children": [],
                        "images": [],
                        "isParent": false
                    }, {
                        "key": 28,
                        "label": "dinamikusortezis",
                        "riscFactor": 3,
                        "criteria": null,
                        "children": [],
                        "images": [],
                        "isParent": false
                    }, {
                        "key": 29,
                        "label": "vegtaghosszkulonbseg",
                        "riscFactor": 3,
                        "criteria": null,
                        "children": [],
                        "images": [],
                        "isParent": false
                    }],
                    "images": [],
                    "isParent": true
                }],
            i18Values,
            i18ValuesHu,
            selectedIllnesses: [],
            filters: []
        }
    },
    async mounted(){
        localStorage.removeItem('illnesses');
        localStorage.removeItem('riscFactor');
        this.filters = (await productFilterClient.byId('categories')).options;
        this.selectedIllnesses = catalogueFilterClient.getState();
    },
    methods: {
        toggleItem(e) {
            const accItem = document.getElementsByClassName('accordionItem');
            const itemClass = e.target.parentNode.className;
            for (let item of accItem) {
                item.className = 'accordionItem close';
            }
            if (itemClass === 'accordionItem close') {
                e.target.parentNode.className = 'accordionItem open';
            }
        },
        checkRiscFactor (riscGroups) {
            if(this.selectedIllnesses.length === 0) {
                return null;
            }
            const countOccurrences = (arr, val) => arr.reduce((a, v) => (v === val ? a + 1 : a), 0)
            if (countOccurrences(riscGroups, 0) === riscGroups.length) {
                return 0
            } else if (countOccurrences(riscGroups, 1) === riscGroups.length || (!riscGroups.includes(2) && !riscGroups.includes(3) && !riscGroups.includes(4))) {
                return 1
            } else if (countOccurrences(riscGroups, 4) > 0) {
                return 4
            } else if (countOccurrences(riscGroups, 2) === 1) {
                return 2
            } else {
                return 3
            }
        },
        save() {
            const riscGroups = this.selectedIllnesses.map((f) => {
                f = f.riscFactor
                return f
            })
            const riscFactor = this.checkRiscFactor(riscGroups);
            if(riscFactor >= 2) {
                this.$emit('warning', true);
            } else {
                this.$emit('warning', false);
            }
            catalogueFilterClient.setState(this.selectedIllnesses);
            localStorage.setItem('illnesses', JSON.stringify(this.selectedIllnesses));
            localStorage.setItem('riscFactor', riscFactor);
        },
        $t(key) {
            return this.$i18n.t(`product-filters.${key}`);
        },
        onFilter(e, id) {
            this.$emit('filter', id);
            const delButton = e.srcElement.nextSibling;
            delButton.classList.remove('is-hidden')
        },
        onDeleteFilter(e, id) {
            this.$emit('deleteCategoryFilter', id);
            e.preventDefault();
            e.srcElement.classList.add('is-hidden');
        },
        selectLanguage() {
            const languages = ["hu", "en"];
            let selectedLanguage = "";
            if(!localStorage.getItem('lang')) {
                if(languages.includes(window.navigator.language.split('-')[0])) {
                    selectedLanguage = window.navigator.language.split('-')[0];
                } else {
                    selectedLanguage = "en";
                }
            }
            if(languages.includes(localStorage.getItem('lang'))) {
                selectedLanguage = localStorage.getItem('lang');
            }
            return selectedLanguage;
        },
        translateIllnessQuestions(key) {
            const selectedLanguage = this.selectLanguage();
            if(selectedLanguage === "hu") {
                return this.i18ValuesHu[key];
            } else {
                return this.i18Values[key];
            }
        },
        translateIllnessQuestionImages(key) {
            const selectedLanguage = this.selectLanguage();
            if(selectedLanguage === "hu") {
                return this.i18ValuesHu.images[key];
            } else {
                return this.i18Values.images[key];
            }
        }
    }
}
</script>

<style>

</style>