<template>
    <div class="level py-5 is-hidden-mobile">
        <div
            class="level-item has-text-centered"
            v-for="(step, index) of steps"
        >
            <div class="is-flex is-justify-content-center is-align-items-center is-flex-direction-column"
                 style="cursor:pointer;"
            >
                <div
                    :class="{
                        'progress-step-circle': true,
                        'is-current': index === currentStep,
                        'is-next': index > currentStep,
                    }"
                ></div>
                <p
                    :class="{'progress-step-name': true, 'is-next': index > currentStep, 'has-text-centered': true}"
                >
                    {{ step }}
                </p>
            </div>
            <hr />
        </div>
    </div>
</template>

<script>
import "./flow-progress.scss";
export default {
    props: {
        steps: Array,
        currentStep: Number,
    }
};
</script>

<style>
</style>
