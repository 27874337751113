<template>
    <scaffold fluid class="catalogue">
        <template #content v-if="error">
            <catalogue-error :error="`${error}`"></catalogue-error>
        </template>
        <template #content v-else>
            <div class="header-line">
              <h3>Egyedi {{catalogue.name}} cipő modellcsaládok</h3>
              <div class="orange-line"></div>
            </div>
          <div class="content">
            <div :class="{'notification': true, 'is-warning': true, 'is-hidden': isHidden}">
                {{$t('product-filters.risc_factor_message')}}
            </div>
            <div class="columns is-multiline">
                <div class="column is-12-mobile is-12-tablet is-3-desktop is-hidden-mobile">
                    <catalogue-filters @filter="onFilter" @deleteCategoryFilter="onDeleteCategoryFilter" @warning="onWarning"></catalogue-filters>
                </div>
                <div class="is-hidden-desktop">
                    <button class="button" @click="openModal">{{ $t('product-filters.symptom') }}</button>
                    <modal ref="modal">
                        <catalogue-filters @filter="onFilter" @deleteCategoryFilter="onDeleteCategoryFilter" @warning="onWarning"></catalogue-filters>
                    </modal>
                </div>
                <div class="column is-12-mobile is-12-tablet is-9-desktop">
                    <catalogue-item-grid :items="items"></catalogue-item-grid>
                  <loading v-if="loading"></loading>
                </div>
                <div class="column is-12" v-if="canScrollMore">
                    <catalogue-infinite-scroller
                        @load="onInfiniteScrollerLoad"
                    ></catalogue-infinite-scroller>
                </div>
            </div>
          </div>
        </template>
    </scaffold>
</template>

<script>
import Scaffold from "@diabfoot/component-scaffold"
import CatalogueFilters from "@diabfoot/component-catalogue-filters";
import CatalogueItemGrid from "@diabfoot/component-catalogue-item-grid";
import CataloguePaging from "@diabfoot/component-catalogue-paging";
import CatalogueInfiniteScroller from "@diabfoot/component-catalogue-infinite-scroller";
import CatalogueNoMoreItems from "@diabfoot/component-catalogue-no-more-items";
import CatalogueError from "@diabfoot/component-catalogue-error";
import {catalogueClient} from "@diabfoot/client-cache";
import Loading from "@diabfoot/component-loading";
import Modal from "@diabfoot/component-modal";
import './catalogue.scss';

export default {
        components: { Scaffold, CatalogueFilters, CatalogueItemGrid, CataloguePaging, CatalogueError, CatalogueInfiniteScroller, CatalogueNoMoreItems, Loading, Modal },
        data() {
            return {
                filters: [],
                sort: null,
                page: 0,
                count: 20,

                total: null,
                items: [],
                loading: true,

                error: null,
                catalogue: {
                    name: null
                },
                isHidden: true,
            }
        },
        inject: ["route"],
        computed: {
            canScrollMore() {
                return (this.page + 1) * this.count - this.total < this.count;
            }
        },
        async mounted() {
            const catalogues = await catalogueClient.listCatalogues();
            //console.log(catalogues.map(c=> c.id));
            this.filters.push(
                {
                    id: "catalog",
                    values: [
                        this.route.groups[1]
                    ]
                    //values: catalogues.map(c=> c.id)
                }
            )
            await this.populate();
        },
        methods: {
            async populate() {

                this.loading = true;

                try {
                    const productFamilies = await catalogueClient.listProductFamilies({
                        productFilters: this.filters
                    });

                    //console.log(JSON.stringify(productFamilies.map(pF => `${pF.name.toLowerCase().split(' ').join('_')}_description`)))

                    this.catalogue = await catalogueClient.getCalatogue(parseInt(this.route.groups[1]));

                    this.items = productFamilies;
                    this.total = productFamilies.length;
                    this.loading = false;
                } catch (err) {
                    this.error = err;
                }

            },
            async onInfiniteScrollerLoad() {
                this.page = this.page + 1;
                await this.populate();
            },
            async onPagingChanged(newCount) {
                this.count = newCount;
                this.loading = true;
                this.items = [];
                await this.populate();
            },
            async onFilter(filterId) {
                const categoryFilter = this.filters.find(filter => filter.id === "categories");
                if(filterId === null) {
                    this.filters = [...this.filters.filter((f) => f.id !== "categories")];
                } else {
                    if(categoryFilter) {
                        if (!categoryFilter.values.find((f) => f === filterId)) {
                            categoryFilter.values.push(filterId);
                        }
                    } else {
                        this.filters.push({
                            id: "categories",
                            values: [filterId]
                        })
                    }
                }
                await this.populate();
            },
            async onDeleteCategoryFilter(filterId) {
                console.log(filterId);
                const categoryFilter = this.filters.find(filter => filter.id === "categories");
                if(categoryFilter) {
                    categoryFilter.values = categoryFilter.values.filter((v) => v !== filterId);
                    if(categoryFilter.values.length === 0) {
                        this.filters = [...this.filters.filter((f) => f.id !== "categories")];
                    }
                }
                await this.populate();
            },
            onWarning(isVisible) {
                this.isHidden = !isVisible;
            },
            $t(key) {
                return this.$i18n.t(key);
            },
            openModal() {
                this.$refs.modal.openModal();
            }
        }
    }
</script>

<style>
</style>