<template>
    <modal ref="modal" class="file-upload" :closeable="false">
        <div class="card">
            <div class="card-content">
                <div class="content">
                    <div class="header-line">
                        <h3>Fotó feltöltése</h3>
                        <div class="orange-line"></div>
                    </div>
                    <div ref="images" class="images-box">
                        <div class="file-upload-button" ref="fileUploadButton">
                            <div class="file is-small is-boxed">
                                <label class="file-label">
                                    <input class="file-input" type="file" name="resume" ref="input"
                                           @change="uploadImage" multiple accept="image/jpeg,image/png"
                                    >
                                    <span class="file-cta">
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="columns is-justify-content-center">
                        <div class="column is-narrow is-flex is-justify-content-center">
                            <button class="back-button" @click="onCancel">
                                {{ $i18n.t('file_upload.back') }}
                            </button>
                            <button class="next-button" @click="onUpload">
                                {{ $i18n.t('file_upload.upload', images.length) }}
                            </button>
                        </div>
                    </div>
                    <label class="max-count-label">
                        {{ $i18n.t('file_upload.max_count', 6) }}
                    </label>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "@diabfoot/component-modal";
import './file-upload.scss';
export default {
    components: {
        Modal
    },
    data() {
        return {
            images: []
        };
    },
    methods: {
        openModal() {
            this.$refs.modal.openModal();
        },
        uploadImage() {
            const fileList = Array.from(this.$refs.input.files);
            if(this.images.length >= 6) {
                //this.$toast.error('Maximum 6 képet tölthetsz fel!');
                return;
            }
            if (fileList.length > 0) {
                fileList.forEach(f => {
                    this.images.push(f);
                    const imageBox = document.createElement('div');
                    const imageBoxWrapper = document.createElement('div');
                    const fileName = document.createElement('p');
                    fileName.innerHTML = f.name;
                    imageBox.className = 'image-box';
                    imageBox.style = "background-image: url('" + URL.createObjectURL(f) + "');";
                    imageBoxWrapper.className = 'image-box-wrapper';
                    imageBoxWrapper.appendChild(imageBox);
                    imageBoxWrapper.appendChild(fileName);
                    this.$refs.fileUploadButton.before(imageBoxWrapper);
                })
            }
        },
        onCancel() {
            this.$refs.modal.closeModal();
            this.images = [];
            const imageBoxWrapper = this.$refs.images.getElementsByClassName('image-box-wrapper');
            Array.from(imageBoxWrapper).forEach(iBW => {
                iBW.remove();
            });
        },
        onUpload() {
            this.$refs.modal.closeModal();
            this.$emit('upload', [...this.images]);
            this.images = [];
        }
    }
}
</script>

<style scoped>

</style>