import getOpt from "@diabfoot/get-opt";
import { ApolloClient, createHttpLink, InMemoryCache, gql } from '@apollo/client/core'
import { createApolloProvider } from '@vue/apollo-option'

export { gql } from "graphql-tag";

async function graphqlFetch(uri, options) {
    const token = localStorage.getItem('token');
    if (token) {
        options.headers.Authorization = `Bearer ${token}`;
    }
    return await fetch(uri, options);
}

export class GraphQLClient {
    constructor(opts) {
        this.baseUrl = getOpt(opts, "baseUrl");
        this.createProvider();
    }

    createProvider() {
        this.apolloClient = new ApolloClient({
            link: this.createHttpLink(),
            cache: new InMemoryCache({
                addTypename: false,
                resultCaching: false
            }),
            connectToDevTools: true
        });
        this.apolloProvider = createApolloProvider({
            defaultClient: this.apolloClient,
        })
    }

    get provider() {
        return this.apolloProvider;
    }

    createHttpLink() {
        return createHttpLink({
            uri: this.baseUrl,
            fetch: graphqlFetch
        });
    }

    async query(query, variables) {
        return await this.apolloClient.query({
            query: typeof query === "string" ? gql(query) : query,
            variables
        });
    }

    async mutate(mutation, variables) {
        return await this.apolloClient.mutate({
            mutation: typeof mutation === "string" ? gql(mutation) : mutation,
            variables
        });
    }
}

